import React from 'react';
import Blogrightsidebar from './Blogrightsidebar';
import ServiceData from '../Service/ServiceData';
const Bloglists =()=>{
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={require('../../img/blog-grid/49573 (1).jpg')} alt=""/>
                            <div className="blog_content">
                                {/*<div className="post_date">*/}
                                {/*    <h2>14 <span>March</span></h2>*/}
                                {/*</div>*/}
                                {/*<div className="entry_post_info">*/}
                                {/*    By: <a href=".#">Admin</a>*/}
                                {/*    <a href=".#">2 Comments</a>*/}
                                {/*    <a href=".#">SaasLand</a>*/}
                                {/*</div>*/}
                                <a href=".#">
                                    <h5 className="f_p f_size_20 f_500 t_color mb-30">Despre Varsta si sex in medicina preventiva</h5>
                                </a>
                                <p className="f_400 mb-30" align="justify">
                                    Varstele cu semnificatie in preventie pentru un adult sunt: 19 ani, 40 de ani, 50 de ani, 65 de ani. Pentru acest motiv vom grupa interventiile preventive pe grupe de varsta care incep in aceste momente.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Anamneza detaliata a fiecarui pacient poate insa decela ca riscul este semnificativ crescut pentru o boala, fie printr-un cumul semnificativ de factori de risc, fie prin antecedente semnificative si atunci pragurile acestea de varsta nu mai sunt aplicabile. De exemplu, daca exista istoric familial de cancer cu transmitere genetica, screeningul va incepe cu cel putin 10 ani mai devreme decat varsta de debut a cancerului la ruda respectiva.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Odata initiate consultatiile preventive si riscul stabilit, intervalele de rechemare sunt variabile pentru diferitele grupe de varsta.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Pentru adultul cu varste intre 19-39 de ani, frecventa vizitelor poate fi la 3 ani, daca nu prezinta istoric familial de boli cardiovasculare sau oncologice, motiv care ar implica initierea precoce.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Persoanele intre 40 si 49 de ani sunt evaluate cu o frecventa anuala, dar continutul vizitei va fi variabil in functie de intervalele de screening pentru fiecare dintre factorii de risc individuali ai pacientului. Astfel, la cei peste 40 de ani, in cazul unui risc cardiovascular sub 5%, indicatia de reevaluare se va face la 3 ani, in schimb persoanele cu varsta cuprinsa intre 50 si 64 de ani sunt supravegheate mai activ avand in vedere ca majoritatea riscurilor devin active in populatia generala. Un accent deosebit se pune pe evaluarea si managementul riscului cardiometabolic.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Varsta de 50 de ani este si varsta de debut al screeningului pentru cancerul de san sau cel de colon. Multe dintre persoanele peste 65 de ani cumuleaza deja afectiuni care sunt urmarite in cadrul managementului de caz. Pentru acest motiv se recomanda ca anumite screeninguri sa fie oprite atunci cand aspectele de patologie greveaza prognosticul pacientului atat de mult incat preventie nu ar mai avea semnificatie. Ghidurile recomanda oprirea anumitor screeninguri la varsta de 75 de ani cum ar fi cele pentru cancer colorectal sau de san.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Vizitele preventive pun accent pe acele patologii care pot duce la invaliditate si handicap, cum ar fi tulburarile de vedere si auz, osteoporoza.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    <b>Femei</b>
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening cancer de col uterin</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Conform ghidurilor de tratament internationale, screeningul cancerului de col uterin consta in realizarea a doua teste: testul Papanicolau (PAP) si testul genetic pentru depistarea infectiei cu virusul papiloma uman (HPV). Testul PAP este recomandat, fara testul HPV, anual, incepand cu varsta de 21 de ani pana la 30 de ani sau la 3 ani de la inceperea vietii sexuale. Testul PAP poate fi realizat conventional (pe lama) sau in mediu lichid.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Dupa implinirea varstei de 30 de ani, se recomanda atat testul PAP, cat si testul HPV la fiecare 3-5 ani. Unii specialisti recomanda doar efectuarea testului PAP la fiecare 3 ani.
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Nu uita:</b>
                                </p>
                                <ul>
                                    <li className="f_400 mb-30" align="justify">Testul PAP este necesar dupa histerectomie doar daca interventia a fost facuta ca urmare a unui cancer sau a unei leziuni precanceroase</li>
                                    <li className="f_400 mb-30" align="justify">Continua screeningul chiar daca ai fost vaccinata impotriva HPV!</li>
                                </ul>
                                <p className="f_400" align="justify">
                                    <b>Screening cancer mamar</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Cancerul mamar poate fi depistat in faze incipiente, cand prognosticul este mult mai bun si optiunile de tratament sunt mai eficiente, prin:
                                </p>
                                <ul>
                                    <li className="f_400 mb-30" align="justify">Mamografie de screening, la 1-3 ani, incepand cu varsta de 40-50 de ani</li>
                                    <li className="f_400 mb-30" align="justify">Examen clinic al sanilor efectuat de medicul specialist ginecolog, anual, incepand cu varsta de 40-50 de ani</li>
                                    <li className="f_400 mb-30" align="justify">Auto-examinarea lunara a sanilor, urmand instructiunile medicului ginecolog, incepand cu varsta de 20 de ani</li>
                                </ul>
                                <p className="f_400 mb-30" align="justify">
                                    <b>Barbati</b>
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening cancer de prostata</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Societatea Americana de Cancer recomanda tuturor barbatilor sa discute cu medicul urolog despre cancerul de prostata, importanta testarii, precum si despre avantajele si riscurile screeningului. Decizia testarii trebuie sa fie una informata. Expertii americani recomanda testarea incepand cu varsta de 50 de ani sau chiar mai devreme (40 de ani) daca exista cazuri de cancer de prostata in familie. Investigatia consta intr-un test de sange pentru determinarea PSA – antigenul specific prostatic, cu sau fara examinarea prostatei prin tuseu rectal. Frecventa testarii depinde de nivelul PSA.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    <b>Femei si barbati</b>
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening cancer colorectal</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Cancerul colorectal este unul dintre cele mai frecvente si mai grave cancere. Din fericire, poate fi depistat in stadii incipiente, prin realizarea unei colonoscopii - metoda de evaluare a rectului si intestinului gros. Colonoscopia este recomandata o data la fiecare 10 ani persoanelor cu risc mediu, incepand cu varsta de 50 de ani. Daca ai un risc crescut in functie de istoricul medical familial sau alti factori de risc, discuta cu medicul tau pentru a afla cand sa incepi testarea.
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening cancer de piele</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Melanomul este unul dintre cele mai agresive tipuri de cancer de piele, insa depistat in faze incipiente poate fi tratat si vindecat cu succes. Screeningul cancerului de piele se recomanda tuturor barbatilor si femeilor incepand cu varsta de 18 ani. Include:
                                </p>
                                <ul>
                                    <li className="f_400 mb-30" align="justify">Examenul clinic al pielii efectuat de medicul dermatolog</li>
                                    <li className="f_400 mb-30" align="justify">Dermatoscopia – examinarea pielii si a alunitelor cu ajutorul unui dermatoscop; este considerata cea mai eficienta metoda de depistare a cancerului de piele</li>
                                </ul>
                                <p className="f_400 mb-30" align="justify">
                                    Complementar, se recomanda lunar autoexaminarea pielii si a alunitelor, pentru a identifica din timp eventualele modificari de forma si culoare. Nu uita de regula ABCDE, care te ajuta sa depistezi cu ochiul liber melanomul!
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening colesterol</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Valorile ridicate ale colesterolului cresc riscul de boala de inima – principala cauza de deces la nivel mondial, atat in randul femeilor, cat si al barbatilor. Prin urmare, determinarea profilului lipidic (LDL colesterol, HDL colesterol, colesterol total, trigliceride) este extrem de importanta in prevenirea si depistarea precoce a bolilor cardiovasculare. Medicul cardiolog, internist sau medicul de Medicina Generala recomanda realizarea unui test de sange pentru determinarea profilului lipidic la fiecare 5 ani, incepand cu varsta de 20 de ani.
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening diabet zaharat</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Este recomandat la fiecare 3 ani, incepand cu varsta de 45 de ani sau mai des la recomandarea medicului in functie de istoricul medical familial. Screeningul consta in realizarea individuala sau la pachet a urmatoarelor analize: masurarea glucozei din sange pe nemancate (glicemie), determinarea tolerantei la glucoza sau a hemoglobinei glicozilate (HbA1c).
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening hipertensiune arteriala</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Consta in masurarea valorilor tensiunii arteriale tuturor persoanelor, indiferent de varsta. Conform ghidurilor internationale, screeningul hipertensiunii arteriale este recomandat  la fiecare 1-2 ani.
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening combinat: boli de inima si diabet zaharat de tip 2</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Este recomandat incepand cu varsta de 20 de ani, la fiecare 2-3 ani, daca valorile investigatiilor sunt in limitele normale si cuprinde urmatoarele teste:
                                </p>
                                <ul>
                                    <li className="f_400 mb-30" align="justify">Profil lipidic</li>
                                    <li className="f_400 mb-30" align="justify">Glicemie</li>
                                    <li className="f_400 mb-30" align="justify">Masurare tensiune arteriala</li>
                                    <li className="f_400 mb-30" align="justify">Masurare Indicele de masa corporala (IMC)</li>
                                </ul>

                                <p className="f_400 mb-30" align="justify">
                                    <b>Copii</b>
                                </p>
                                <p className="f_400" align="justify">
                                    <b>Screening vizual</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Copiii trebuie evaluati regulat pentru a depista din timp eventualele tulburari de vedere. In primul an de viata copilul nu iti poate spune ca nu vede, el se bazeaza pe tine ca parinte sa depistezi eventualele tulburari de vedere, prin realizarea screeningului oftalmologic precoce.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Ghidurile internationale de screening recomanda realizarea examenului oftalmologic:
                                </p>
                                <ul>
                                    <li className="f_400 mb-30" align="justify">Inainte de externarea copilului din maternitate sau intre 6 si 12 luni</li>
                                    <li className="f_400 mb-30" align="justify">La 3 ani</li>
                                    <li className="f_400 mb-30" align="justify">La 5 ani (inainte de a incepe scoala)</li>
                                </ul>
                                <p className="f_400" align="justify">
                                    <b>Screening auditiv</b>
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Conform Centrului pentru Prevenirea si Controlul Bolilor (CDC) din Statele Unite ale Americii, calitatea auzului trebuie verificata la toti copiii, prin realizarea audiometriei inainte de externarea din maternitate sau in prima luna de viata a copilului. Efectuarea testelor preliminare inca din maternitate permite obtinerea unor informatii certe despre nivelul auzului nou-nascutului. In cazul in care testarea evidentiaza posibilitatea unei hipoacuzii, copilul trebuie supus in cel mai scurt timp unei evaluari audiologice complexe.
                                </p>
                                <div className="post_share"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <Blogrightsidebar ServiceData={ServiceData}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;
