import jwt from 'jsonwebtoken';

export const getJwtSub = () => {
    const token = localStorage.getItem('token');
    try {
        const decodedToken = jwt.decode(token);
        return decodedToken.sub;
    } catch (err) {
        console.error(err);
        return null;
    }

}


export const getJwtRole = () => {
    const token = localStorage.getItem('token');
    try {
        const decodedToken = jwt.decode(token);
        return decodedToken.role;
    } catch (err) {
        console.error(err);
        return null;
    }
}

