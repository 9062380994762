import React from 'react';
import FileDownload from "js-file-download";
import {downloadFile} from "../../Pages/requestFunctions";
import {t} from "i18next";

const CloudFeaturesTwo =()=>{
    function handleDownload(e) {
        e.preventDefault();
        let fileName = "studiu plus anexa caravane.pdf";
        downloadFile(fileName).then((res) => {
            FileDownload(res, `${fileName}`)
        })
    }

    return(
        <section className="software_featured_area_two sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="software_featured_img wow fadeInLeft" data-wow-delay="0.2s">
                            <img className="img-fluid" src={require("../../img/home5/img1.png")} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 d-flex align-items-center pl-0">
                        <div className="software_featured_content">
                            {/*<h2 className="f_700 f_size30 l_height_40 w_color f_p mb-30 wow fadeInRight" align="justify" data-wow-delay="0.2s">RAPORT ASUPRA GRADULUI DE DEZVOLTARE AL UNITATILOR ADMINISTRATIV TERITORIALE DE LA NIVELUL JUDETULUI PRAHOVA SI ACCESUL ACESTORA LA SERVICII DE SANATATE.</h2>*/}
                            <p className="w_color f_400 mb_50 wow fadeInRight f_size_20" data-wow-delay="0.4s" align="justify"><b>{t("RAPORT ASUPRA GRADULUI DE DEZVOLTARE AL UNITATILOR ADMINISTRATIV TERITORIALE DE LA NIVELUL JUDETULUI PRAHOVA SI ACCESUL ACESTORA LA SERVICII DE SANATATE.")}</b></p>

                            <p className="w_color f_400 mb_50 wow fadeInRight" data-wow-delay="0.4s" align="justify">{t("Studiu comandat de catre Consiliu Judetean Prahova finantat de OMV Petrom si realizat de Asociatia Medcorp.")}</p>
                            <div className="btn_hover btn_four wow fadeInRight" data-wow-delay="0.6s" onClick={handleDownload}>{t("Descarca raport")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CloudFeaturesTwo;
