import React from 'react';
import Sectitle from '../Title/Sectitle';
import Teamitem from '../Team/Teamitem';
import Reveal from 'react-reveal';
import {t} from "i18next";
const AppTeam =()=>{
    return(
        <section className="experts_team_area sec_pad" id="team">
            <div className="container">
                <Sectitle sClass="sec_title text-center mb_70" Title={t("Echipa Medcorp")} tClass="t_color3" TitleP={t("Munca in echipa este vitala pentru noi. Punem succesul colectiv inaintea intereselor personale.  Depasim limitele pentru a face diferenta.")}/>
                <Reveal bottom cascade>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6" >
                            <Teamitem teamImage="Picture2.jpg" memberN="Mihai Olaru" memberd={t("Coordonator General")} email="mihai.olaru@medcorp.ro" tel="0722 632 414"/>
                        </div>
                        <div className="col-lg-3 col-sm-6" >
                            <Teamitem teamImage="Picture1 (1).jpg" memberN="Radu Prodan" memberd={t("Coordonator Medical")} email="radu.prodan@medcorp.ro" tel="0743 740 862"/>
                        </div>
                        <div className="col-lg-3 col-sm-6" >
                            <Teamitem teamImage="Picture4.jpg" memberN={t("Medici")} memberd={t("Peste 10 specialitati")} email="" tel=""/>
                        </div>
                        <div className="col-lg-3 col-sm-6" >
                            <Teamitem teamImage="Picture3.jpg" memberN={t("Echipa de voluntari")} memberd={t("Peste 120 de membrii activi")} email="" tel=""/>
                        </div>
                    </div>
                </Reveal>
            </div>
        </section>

    )
}
export default AppTeam;
