import React from 'react';
import Eventgalleryitem from './Eventgalleryitem';

const Eventgallery =()=>{
    return(
        <section className="event_gallery_area">
            <div className="row m0 portfolio_gallery event_gallery">
                <Eventgalleryitem image="poza1.png" title="" bname="" bname2=""/>
                <Eventgalleryitem image="poza2.png" title="" bname="" bname2=""/>
                <Eventgalleryitem image="poza3.png" title="" bname="" bname2=""/>
                <Eventgalleryitem image="poza4.png" title="" bname="" bname2=""/>
                <Eventgalleryitem image="poza5.png" title="" bname="" bname2=""/>
                <Eventgalleryitem image="poza6.png" title="" bname="" bname2=""/>
            </div>
        </section>
    )
}
export default Eventgallery;
