import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
class BlogGridItem extends Component{
    render(){
        let{blogId, date, month, Title, description, btn, comment, image}=this.props;
        return(
            <div className="col-lg-6">
                <div className="blog_list_item blog_list_item_two">
                    {/*<a href="/#" className="post_date">*/}
                    {/*    <h2>{date} <span>{month}</span></h2>*/}
                    {/*</a>*/}
                    <NavLink to={`/Noutati-${blogId}`}><img className="img-fluid" src={require ("../../img/new/blog/" + image)}alt="" style={{objectFit: "cover", objectPosition: "center", minHeight: "250px", height: "250px", width: "100%"}}/></NavLink>
                    <div className="blog_content">
                        <div className="blog-wrapper">
                            <NavLink to={`/Noutati-${blogId}`}>
                                <h5 className="blog_title">{Title}</h5>
                                {blogId === "2" && (
                                    <br/>
                                )}
                            </NavLink>
                            <p>{description}</p>
                            <div className="post-info-bottom">
                                <NavLink to={`/Noutati-${blogId}`} className="learn_btn_two">{btn} <i className="arrow_right"></i></NavLink>
                                {/*<a className="post-info-comments" href="/#">*/}
                                {/*    <i className="icon_comment_alt" aria-hidden="true"></i>*/}
                                {/*    <span>{comment} Comments</span>*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BlogGridItem;
