import {atomWithStorage} from "jotai/utils";

const user = atomWithStorage("user", null);
export const currentUser = {user};

const blogs = atomWithStorage("blogs", []);
export const currentBlogs = {blogs};

// export const languageAtom = atomWithStorage('language', 'ro');
export const languageAtom = atomWithStorage('language', null);
