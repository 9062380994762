import React from 'react';
import Navbar from "../Navbar";
import AgencyBanner from "../../components/Banner/AgencyBanner";
import BannerData from "../../components/Banner/BannerData";
import BlogGrid from "../../components/Blog/BlogGrid";
import Blogsingle from "../../components/Blog/Blogsingle";
import AgencyAction from "../../components/AgencyAction";
import FooterData from "../../components/Footer/FooterData";
import FooterTwo from "../../components/Footer/FooterTwo";

const Noutate = () => {
    return (
        <div>
            <div className="body_wrapper">
                <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
                <AgencyBanner BannerData={BannerData}  image="" title="" title1="" title2="" description="" classBG="pagina_blog"/>
                <Blogsingle/>
                <AgencyAction FooterData={FooterData}/>
                <FooterTwo FooterData={FooterData}/>
            </div>
        </div>
    );
};

export default Noutate;
