import React from 'react';
import Sectitle from '../components/Title/Sectitle';
import Slider from 'react-slick';
import {t} from "i18next";

const video = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return(
        <section className="video_area bg_color sec_pad">
            <div className="container">
                <Sectitle Title={t("RECUNOASTERE")} TitleP={t("Programele derulate de Medcorp sunt remarcate si premiate")} tClass="t_color3" sClass="sec_title text-center mb_70"/>
                <div className="video_content">
                    <Slider className="about_img_slider" {...settings}>
                        <div className="item">
                            <div className="video_content">
                                <div className="video_info bg-poza1"></div>
                                <img className="video_leaf" src={require('../img/new/lauri.png')} alt=""/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="video_content">
                                <div className="video_info bg-poza2"></div>
                                <img className="video_leaf" src={require('../img/new/lauri.png')} alt=""/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="video_content">
                                <div className="video_info bg-poza3"></div>
                                <img className="video_leaf" src={require('../img/new/lauri.png')} alt=""/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="video_content">
                                <div className="video_info bg-poza4"></div>
                                <img className="video_leaf" src={require('../img/new/lauri.png')} alt=""/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="video_content">
                                <div className="video_info bg-poza5"></div>
                                <img className="video_leaf" src={require('../img/new/lauri.png')} alt=""/>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default video;
