import React from 'react';
import AgencyBanner from "../../components/Banner/AgencyBanner";
import BannerData from "../../components/Banner/BannerData";
import AgencyAction from "../../components/AgencyAction";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import ChatClients from "../../components/chatClients";
import Navbar from "../Navbar";
import {Element} from "react-scroll";
import ErpFeaturesitem from "../../components/Features/ErpFeaturesitem";
import Service from "../../components/Service/Service";
import CloudFeaturesTwo from "../../components/Features/CloudFeaturesTwo";
import {t} from "i18next";

const StudiiStatistica = () => {
    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title={t("Fii informat")} title1={t("pentru decizii")} title2={t("cu impact")} description="" classBG="informare"/>
            <Service/>
            <CloudFeaturesTwo />
            <div>
                <section className="erp_features_area_two sec_pad" >
                    <div className="container">
                        <Element id='inscriere' name='inscriere'>
                            <ErpFeaturesitem rowClass="align-items-center flex-row-reverse"
                                             image="romania-national-healthcare-syst.jpg"
                                             title={t("State of Health in the EU · Romania · Profilul de tara din 2021 in ceea ce priveste sanatatea")}
                                             text1={t("Speranta de viata in Romania este printre cele mai scazute din Europa, iar pandemia de COVID-19 a contrabalansat o parte din cresterea inregistrata incepand cu 2000. Pandemia a evidentiat importanta consolidarii asistentei medicale primare, a serviciilor de preventie si a sanatatii publice intr-un sistem de sanatate care, in prezent, depinde in mare masura de serviciile spitalicesti. Deficitul de forta de munca din sectorul sanitar si cheltuielile mari suportate de pacient reprezinta bariere esentiale in calea accesului. Pandemia de COVID-19 a stimulat crearea mai multor sisteme electronice de informatii pentru a gestiona mai bine resursele suprasolicitate din domeniul sanatatii, iar acestea pot oferi cai de consolidare a sistemelor de sanatate pe viitor.")}
                                             text=""
                                             icon1Text=""
                                             icon2Text=""
                            />
                        </Element>
                        <Element id='perioada' name='perioada'>
                            <ErpFeaturesitem rowClass="align-items-center"
                                             image="Grafic1.PNG"
                                             roundClass="red"
                                             title={t("Speranta de viata")}
                                             text1=""
                                             text={t("Speranta de viata in Romania a crescut cu peste patru ani intre 2000 si 2019, dar a scazut temporar cu 1,4 ani in 2020 din cauza impactului pandemiei de COVID-19. Exista o disparitate de gen majora, femeile traind cu aproape opt ani mai mult decat barbatii. Bolile cardiovasculare sunt principalele cauze ale mortalitatii, in timp ce cancerul pulmonar este cea mai frecventa cauza de decese provocate de cancer.")}
                                             icon1Text=""
                                             icon2Text=""
                            />
                        </Element>

                        <Element id='perioada' name='perioada' style={{paddingTop: "70px"}}>
                            <ErpFeaturesitem rowClass="align-items-center flex-row-reverse"
                                             image="Grafic2.PNG"
                                             title={t("Factori de risc")}
                                             text1={t("Comportamentele riscante in materie de sanatate contribuie la aproape jumatate din totalul deceselor. Rata consumului de alcool si a alimentatiei nesanatoase raportate in randul romanilor este mai mare comparativ cu media UE, dar obezitatea la adulti este cea mai scazuta din UE. In prezent, prevalenta fumatului in randul adultilor este usor mai scazuta decat media UE. Acesti factori de risc sunt mai frecventi in randul barbatilor decat in randul femeilor. Excesul de greutate, obezitatea si fumatul in randul adolescentilor sunt ridicate si au crescut constant in ultimele doua decenii.")}
                                             text=""
                                             icon1Text=""
                                             icon2Text=""
                            />
                        </Element>
                        <Element id='perioada' name='perioada' style={{paddingTop: "20px"}}>
                            <ErpFeaturesitem rowClass="align-items-center"
                                             image="Grafic3.PNG"
                                             roundClass="red"
                                             title={t("Sistemul de sanatate")}
                                             text1={t("Cheltuielile pentru sanatate in Romania au crescut in ultimul deceniu, dar raman la al doilea cel mai scazut nivel din UE in ansamblu - atat ca procent din PIB, cat si ca pondere pe cap de locuitor. In 2019, aproximativ 44% din cheltuielile pentru sanatate au fost alocate serviciilor spitalicesti, ceea ce reprezinta cel mai mare procent la nivelul tarilor UE. Desi ponderea cheltuielilor publice pentru sanatate este ridicata si in concordanta cu media UE, platile directe sunt peste media UE si sunt dominate de costurile farmaceutice in ambulatoriu.")}
                                             text=""
                                             icon1Text=""
                                             icon2Text=""
                            />
                        </Element>
                        <Element id='perioada' name='perioada' style={{paddingTop: "30px"}}>
                            <ErpFeaturesitem rowClass="align-items-center flex-row-reverse"
                                             image="Grafic4.PNG"
                                             title={t("Eficacitate")}
                                             text1={t("Rata mortalitatii evitabile este a treia cea mai ridicata din UE, principalele cauze ale acesteia fiind bolile cardiovasculare, cancerul pulmonar si decesele cauzate de consumul de alcool. Mortalitatea prin cauze tratabile este de peste doua ori mai mare decat media UE si include decesele cauzate de cancer de prostata si mamar care pot fi tratate.")}
                                             text=""
                                             icon1Text=""
                                             icon2Text=""
                            />
                        </Element>
                        <Element id='perioada' name='perioada' style={{paddingTop: "50px"}}>
                            <ErpFeaturesitem rowClass="align-items-center"
                                             image="Grafic5.PNG"
                                             roundClass="red"
                                             title={t("Accesibilitate")}
                                             text1={t("Deși nevoile nesatisfăcute autoraportate în materie de examinări medicale în 2019 au scăzut cu mai mult de jumătate comparativ cu 2011, s-a înregistrat o rată crescută a renunțării la asistență medicală în primul an al pandemiei. Teleconsultațiile nu au fost utilizate la fel de mult ca în alte țări ale UE.")}
                                             text=""
                                             icon1Text=""
                                             icon2Text=""
                            />
                        </Element>
                    </div>
                </section>
            </div>

            <ChatClients/>
            <AgencyAction FooterData={FooterData}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
};

export default StudiiStatistica;
