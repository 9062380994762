import React, {Component} from 'react';
import axiosInstance from "./Helper/AxiosHelper";
import {t} from "i18next";

class Contacts extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    async submitForm(e) {
        e.preventDefault();
        try {
            const { name, email, subject, message } = this.state;
            const res = await axiosInstance.post('/api/forms', { name, email, subject, message });
            if (res.status === 200) {
                // reset the form fields on successful submission
                this.setState({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
                // show success message
                // const successMsg = document.getElementById('success');
                // successMsg.style.display = 'block';
                // setTimeout(() => (successMsg.style.display = 'none'), 3000); // hide the message after 3 seconds
            }
        } catch (error) {
            console.error(error);
            // show error message
            const errorMsg = document.getElementById('error');
            errorMsg.style.display = 'block';
            setTimeout(() => (errorMsg.style.display = 'none'), 3000); // hide the message after 3 seconds
        }
        if (document.getElementById('error').style.display !== 'block') {
            const successMsg = document.getElementById('success');
            successMsg.style.display = 'block';
            setTimeout(() => (successMsg.style.display = 'none'), 3000);
        }

    }

    render(){
        // const {name, email, subject, message, emailStatus} = this.state;
        return(
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">{t("Asociatia Medcorp")}</h6>
                                <p className="f_400 f_size_15" align="justify">{t("Infiintata conform (in temeiul) sentintei civile Incheiere nr. 60/31.08.2021 a Tribunalului Giurgiu")}</p>
                                <p className="f_400 f_size_15" align="justify">{t("Cod de identificare fiscala: 44891879")}</p>
                                <p className="f_400 f_size_15" align="justify">{t("Inregistrata in Registrul Special al Asociatiilor si Fundatiilor tinut de Judecatoria Giurgiu sub nr. 63/09.09.2021.")}</p>
                                <p className="f_400 f_size_15" align="justify">{t("Sediul: Bucuresti, Strada Alexandru Sihleanu, Nr. 16, Apt. 2, Sect. 3")}</p>
                                <p className="f_400 f_size_15" align="justify">{t("Presedinte al consiliului director: Mihai Olaru")}</p>
                                <p className="f_400 f_size_15" align="justify">RO02BTRLRONCRT0628476201</p>
                            </div>
                            <div className="contact_info_item">
                                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">{t("Contact")}</h6>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">{t("Telefon:")}</span> <a href="tel:+40722632414">+40 722 632 414</a></p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Email:</span> <a href="mailto:office@medcorp.ro">office@medcorp.ro</a></p>
                            </div>
                        </div>
                        <div className="contact_form col-lg-8">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">{t("Scrie-ne un mesaj")}</h2>
                            <form onSubmit={this.submitForm} className="contact_form_box" method="post" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" id="name" name="name" placeholder={t("Numele tau")} onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group text_box">
                                            <input type="text" name="email" id="email" placeholder={t("Email-ul tau")} onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <input type="text" id="subject" name="subject" placeholder={t("Subiect")} onChange={this.handleChange}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea onChange={this.handleChange} name="message" id="message" cols="30" rows="10" placeholder={t("Scrie mesajul . . .")}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn_three">{t("Trimite mesaj")}</button>
                            </form>
                            {/* {emailStatus ? emailStatus : null} */}
                            <div id="success">{t("Am primit mesajul tau! Multumim!")}</div>
                            <div id="error">{t("Mesajul nu a putut fi trimis, te rog reincearca!")}</div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }

}

export default Contacts;
