import {t} from "i18next";

const ServiceData = {
    STitle: 'You SaasLand wherever your business agency',
    Service: [
        {
            id : 1,
            shape : 'icon_shape1.png',
            icon : 'ti-panel',
            text : 'Dr. Google',
            description: '',
            l1 : "In fiecare minut, peste 70.000 de oameni pun intrebari privind probleme de sanatate pe Google",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 2,
            shape : 'icon_shape2.png',
            icon : 'ti-layout-grid2',
            text : 'Bucatar:',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 3,
            shape : 'icon_shape3.png',
            icon : 'ti-gallery',
            text : 'Operator introducere validare baze de date:',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            l1 : "Curs de nivel 3 ",
            l2 : "Durata: 720 ore  ",
            l3 : "O treime reprezinta teorie (240 ore) si doua treimi reprezinta practica (480 ore) ",
            l4 : "Pot participa tinerii din categoriile B, C si D ",
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        },
        {
            id : 4,
            shape : 'icon_shape4.png',
            icon : 'ti-fullscreen',
            text : 'Pixel Precision',
            description: 'Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!',
            btn: 'Read More',
            bicon: 'ti-arrow-right',
        }
    ],
    agtext1: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext2: 'Why I say old chap that is spiffing bodge, blag pardon.',
    agtext3: 'Starkers dropped a clanger lurgy is cack excuse my French what.',
    pServiceTitle: 'Our Services',
    pServiceDetails: 'Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!',
    PaymentService: [
        {
            id: 1,
            image: 'icon4.png',
            title: 'Safer',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 2,
            image: 'icon7.png',
            title: 'Flexible',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 3,
            image: 'icon5.png',
            title: 'Convinient',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 4,
            image: 'icon8.png',
            title: 'Protected',
            description: 'Well at public scho cheeky bugger grub burke.'
        },
        {
            id: 5,
            image: 'icon6.png',
            title: 'World wide',
            description: 'Well at public scho cheeky bugger grub burke.'
        }
    ],
    HRService: [
        {
            id: '1',
            HRtitles : 'Dr. Google',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'google',
            rclass : 'pr_50',
            iclass: 'icon_one',
            l1 : "",
            l2 : "",
            l3 : "In fiecare minut, peste 70.000 de oameni pun intrebari privind probleme de sanatate pe Google.",
            l4 : "",
            textColor: "#7142f8"
        },
        {
            id: '2',
            HRtitles : 'Piata Muncii',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'direction-alt',
            rclass : 'pr_50',
            iclass: 'icon_two',
            l1 : "",
            l2 : "",
            l3 : "Problemele de sanatate afecteaza major piata muncii; exista o relatie cauzala bidirectionala intre sanatate si ocupare.",
            l4 : "",
            textColor: "#5cbd2c"
        },
        {
            id: '5',
            HRtitles : 'Pandemie COVID-19',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'heart-broken',
            rclass : 'pr_50',
            iclass: 'icon_five',
            l1 : "",
            l2 : "",
            l3 : "Criza COVID-19 a plasat lumina reflectoarelor cu fermitate asupra importantei diagnosticarii de calitate.",
            l4 : "",
            textColor: "#fa4c9a"
        },
        {
            id: '4',
            HRtitles : 'Fonduri insuficiente',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'alert',
            rclass : 'pr_50',
            iclass: 'icon_four',
            l1 : "",
            l2 : "",
            l3 : "In Europa ponderea cheltuielilor dedicate prevenirii este prea scazuta, estimata la mai putin de 5% din cheltuielile totale.",
            l4 : "Sursa: Biblioteca OECD Accesibilitate si protectie financiara.",
            textColor: "#ecbf24"
        },

        {
            id: '3',
            HRtitles : 'Cheltuieli publice in scadere',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'stats-down',
            rclass : 'pr_50',
            iclass: 'icon_three',
            l1 : "",
            l2 : "",
            l3 : "Cheltuielile cu sanatatea publica vor scadea in 2023 in termeni reali, avand in vedere inflatia ridicata si cresterea economica lenta.",
            l4 : "Sursa: Economist Intelligence Healthcare outlook 2023",
            textColor: "#f38650"
        },
        {
            id: '6',
            HRtitles : 'Limitari financiare',
            HRdescription: 'Bog cheeky bugger blow off only a quid grub he legged it porkies tosser young delinquent argy-bargy.',
            Hicon: 'home',
            rclass : 'pr_50',
            iclass: 'icon_six',
            l1 : "",
            l2 : "",
            l3 : "In medie, 30% din gospodariile aflate sub pragul saraciei din Europa nu isi permit serviciile de sanatate",
            l4 : "Sursa: Biblioteca OECD Accesibilitate si protectie financiara.",
            textColor: "#35bfe7"
        }
    ],
    rpost:[
        {
            id: '1',
            image: 'blog-grid/49573 (1).jpg',
            ptitle: 'Despre Varsta si sex in medicina preventiva.',
            admin: 'Admin',
            date: 'March 1, 2019'
        },
        {
            id: '2',
            image: 'blog-grid/Tiny doctor and heart of patient.jpg',
            ptitle: 'Hipertensiunea arteriala',
            admin: 'Admin',
            date: 'March 10, 2019'
        },
        // {
        //     id: '03',
        //     image: 'blog-grid/post_3.jpg',
        //     ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
        //     admin: 'Admin',
        //     date: 'jan 10, 2019'
        // },
        // {
        //     id: '04',
        //     image: 'blog-grid/post_4.jpg',
        //     ptitle: 'Proin gravi nibh velit auctor aliquet aenean.',
        //     admin: 'Admin',
        //     date: 'March 1, 2019'
        // }
    ],
    Blist: [
        {
            id: '01',
            image: 'blog-grid/blog_list1.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '02',
            image: 'blog-grid/blog_list2.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'June'
        },
        {
            id: '03',
            image: 'blog-grid/blog_list3.jpg',
            btitle: 'Why I say old chap that is, spiffing jolly good.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '10',
            Month: 'March'
        },
        {
            id: '04',
            image: 'blog-grid/blog_list4.jpg',
            btitle: 'Bloke cracking goal the full monty get stuffed mate posh.',
            bdescription: 'Cheeky bugger amongst bugger knees up get stuffed mate what a load of rubbish starkers dropped a clanger say boot, blow off cobblers butty mufty spiffing Eaton the full monty the BBC a, posh chap.',
            pdate: '01',
            Month: 'July'
        },
    ],
    work: [
        {
            id: '01',
            icon: 'ti-settings one',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '02',
            icon: 'ti-heart-broken two',
            title: 'Premium plugins',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        },
        {
            id: '03',
            icon: 'ti-target  three',
            title: 'Live page builder',
            p: "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!",
        }
    ]
}
export default ServiceData;
