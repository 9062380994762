import React, {Component} from 'react';

class ErpIconitem extends Component {
    render(){
        let {eitemClass, iconName, erpText} = this.props;
        return(
            <div className="media erp_item" style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                flex: "1"
            }}>
                <div className={`icon ${eitemClass}`}>
                    <i className={`${iconName}`}></i>
                </div>
                <div className="media-body" >
                    <h5 className="text-justify">{erpText}</h5>
                </div>
            </div>
        )
    }
}
export default ErpIconitem;
