import React, {useState} from 'react';
import {useAtom} from "jotai";
import axiosInstance from "./Helper/AxiosHelper";
import BorderSpinner from "../Pages/BorderSpinner";
import {getJwtSub} from "./Helper/jwt_helper";
import {currentUser} from "../state";


// export const userAtom = atom(localStorage.getItem("user"));

const SignInFrom =()=>{
    const [user, setUser] = useAtom(currentUser.user);
    const [showWrongCredentials, setShowWrongCredentials] = useState(false);
    const [isLoading, setLoading] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const user = JSON.stringify({
            email: event.target.email.value.toString().trim(),
            password: event.target.password.value.toString(),
        })
        try {
            setLoading(true);
            const {token} = await axiosInstance.post('/api/v1/auth/authenticate', user, {
                headers: {"Content-Type": "application/json"}
            });
            localStorage.setItem('token', token)
            setUser(getJwtSub)
            // setUserFromAtom(res.data.email);
            setShowWrongCredentials(false);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setShowWrongCredentials(true);
        }
    };
    const handleLogout = () => {
        setUser(null);
        localStorage.clear();
        // setUserFromAtom(null);
    };

    return(
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        {localStorage.getItem('token') && (
                                <div className="col-lg-12" style={{ paddingBottom: '50px'}}>
                                        <h3 align="center">{getJwtSub()}  <b>este conectat!</b></h3>
                                </div>

                        )}
                        {isLoading && (
                            <>
                                <div className="col-lg-12" align="center">
                                    <p>Se conecteaza la platforma</p>
                                    <BorderSpinner />
                                </div>
                            </>
                        )}
                        {!localStorage.getItem('token') && !isLoading && (
                            <>
                                <div className="col-lg-3"></div>
                                <div className="col-lg-6">
                                    <div className="login_info">
                                        <form onSubmit={handleSubmit} className="login-form sign-in-form">
                                            <div className="form-group text_box">
                                                <label className="f_p text_c f_400">E-mail:</label>
                                                <input type="text" name="email" placeholder="E-mail"/>
                                            </div>
                                            <div className="form-group text_box">
                                                <label className="f_p text_c f_400">Parola:</label>
                                                <input type="password" name="password" placeholder="Parola"/>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <button type="submit" className="btn_three">Conectare</button>
                                            </div>
                                            {showWrongCredentials && <span>Utilizator sau parola gresite</span>}
                                        </form>
                                    </div>
                                </div>
                            </>
                        )}
                        {localStorage.getItem('token') && (
                            <div className="col-lg-12" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <div className="login_info">
                                    <form onSubmit={handleLogout} className="login-form sign-in-form">
                                        <div className="d-flex justify-content-between" >
                                            <button type="submit" className="btn_three">Deconectare</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}


                    </div>
                </div>
            </div>
        </section>
    )
}
export default SignInFrom;
