import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import roTranslation from './locales/ro/translation.json';
import enTranslation from './locales/en/translation.json';
import {languageAtom} from "./state";

// const userLanguage = window.navigator.language || window.navigator.userLanguage;
// const isFromRomania = userLanguage.toLowerCase().startsWith('ro');

const storedLanguage = localStorage.getItem('language');

i18n
    .use(initReactI18next)
    .init({
        resources: {
            ro: {
                translation: roTranslation,
            },
            en: {
                translation: enTranslation,
            },
        },
        fallbackLng: storedLanguage,
        lng: storedLanguage,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
export const changeLanguage = (language) => {
    i18n.changeLanguage(language);
};
