import React, {Component} from 'react';
import Plx from "react-plx";
import Reveal from "react-reveal"
import {t} from "i18next";

class AppFeaturesTwo extends Component{
    render(){
        const exampleParallaxData = [
              {
                start: 700,
                duration: 100,
                properties: [
                  {
                    startValue: -100,
                    endValue: -20,
                    property: "translateY"
                  },
                  {
                    startValue: 1,
                    endValue: 20,
                    property: "translateX"
                  },
                  {
                    startValue: 0,
                    endValue: 20,
                    property: "translate"
                  },
                  {
                    startValue: 0,
                    endValue: 2,
                    property: "translateY"
                  }
                ]
              }
          ];
          const exampleParallaxDatas = [
              {
                start: 700,
                duration: 100,
                properties: [
                  {
                    startValue: 100,
                    endValue: 30,
                    property: "translateY"
                  },
                  {
                    startValue: 1.5,
                    endValue: -20,
                    property: "translateX"
                  },
                  {
                    startValue: 0,
                    endValue: 20,
                    property: "translate"
                  },
                  {
                    startValue: 0,
                    endValue: 5,
                    property: "translateY"
                  }
                ]
              }
          ];
        return(
            <section className="app_featured_area" id="features">
                <div className="container">
                    <div className="row flex-row-reverse app_feature_info">
                        <div className="col-lg-6">
                            <div className="app_fetured_item">
                                <Plx className="app_item item_one" parallaxData={exampleParallaxData}>
                                    <i className="ti-settings f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">{t("Logistica inclusa")}</h6>
                                </Plx>
                                <Plx className="app_item item_two"  parallaxData={exampleParallaxDatas}>
                                    <i className="ti-target f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">{t("Specialisti multidiscipline")}</h6>
                                </Plx>
                                <div className="app_item item_three" data-parallax='{"x": 50, "y": 10}'>
                                    <i className="ti-key f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">{t("Campanii la cheie")}</h6>
                                </div>
                                <div className="app_item item_four mb_90" data-parallax='{"x": -20, "y": 50}'>
                                    <i className="ti-map-alt f_size_40 w_color"></i>
                                    <h6 className="f_p f_400 f_size_16 w_color l_height45">{t("Prezenta nationala")}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                          <Reveal bottom cascade>
                              <div className="app_featured_content">
                                {/*<h2 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">Inspired design for the digital app landing</h2>*/}
                                <p className="f_400" align="justify">
                                    {t("Proiectele Asociatiei Medcorp aduc impreuna medici si persoane din mediul economic cu experienta in desfasurarea proiectelor centrate pe sanatatea comunitara, dornici sa aduca o schimbare in randul comunitatilor din tara.")}
                                </p>
                                  <p className="f_400" align="justify">
                                      {t("Medici fiind, ne confruntam, din pacate, in fiecare zi cu consecintele lipsei de asistenta medicala adecvata la nivelul tuturor comunitatilor din Romania, in randul tuturor oamenilor. Speram ca actiunile noastre sa reprezinte pasi spre declansarea unor mecanisme de prevenire si diagnosticare medicala in interiorul comunitatilor sprijinite.")}
                                  </p>
                                {/*<a href=".#" className="learn_btn_two">See All Featureds <i className="ti-arrow-right"></i></a>*/}
                              </div>
                          </Reveal>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default AppFeaturesTwo;
