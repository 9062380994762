import React from 'react';
import AgencyBanner from '../../components/Banner/AgencyBanner';
import BannerData from '../../components/Banner/BannerData';
import ServiceData from '../../components/Service/ServiceData';
import AgencyAction from '../../components/AgencyAction';
import FooterTwo from '../../components/Footer/FooterTwo';
import FooterData from '../../components/Footer/FooterData';
import Navbar from "../Navbar";
import HRService from "../../components/Service/HRService";
import CopyOfServiceDetails from "../../components/Service/CopyOfServiceDetails";
import HostingAction from "../../components/HostingAction";
import EventFeatures from "../../components/Features/EventFeatures";
import Process from "../Process";
import Designtab from "../../components/Features/Designtab";
import EventFact from "../../components/EventFact";
import Eventgallery from "../../components/Portfolios/Eventgallery";
import MarketingGetstarted from "../../components/MarketingGetstarted";
import Portfoliofull4col from "../../components/Portfolios/Portfoliofull4col";
import ScreenshowCase from "../../components/ScreenshowCase";
import Video from "../../components/Video";
import AppTestimonial from "../../components/Testimonial/AppTestimonial";
import StartupFeatures from "../../components/Features/StartupFeatures";
import {t} from "i18next";

const DespreNoi = () => {
    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus" slogo="sticky_logo"/>
            <AgencyBanner BannerData={BannerData} image="Banner  Sud Munteniau 1080 x 108.jpg" title={t("Servicii medicale")} title1={t("mai aproape")} title2={t("de comunitati")} description="" classBG="bg_color"/>
            <CopyOfServiceDetails />
            <HRService ServiceData={ServiceData}/>
            <HostingAction aClass="h_action_area" aimage="element 300x319.png" colClass="col-lg-6" colClassTwo="col-lg-6" Atitle="Managed wordpress Hosting on all plans" Atext="Spiffing pukka show off show off pick your nose and blow off easy peasy buggered hotpot Harry, pardon me blatant.!"/>
            <EventFeatures />
            <Process />
            <Designtab />
            <EventFact />
            <Eventgallery />
            <MarketingGetstarted />
            <Portfoliofull4col />


            <ScreenshowCase />
            <Video/>
            {/*    <AppTestimonial/>*/}
            {/*<StartupFeatures/>*/}

            <AgencyAction FooterData={FooterData}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default DespreNoi;
