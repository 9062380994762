import axiosInstance from "../components/Helper/AxiosHelper";


export const getAllBlogs = async () => {
    const response = await axiosInstance.get('/api/blogs');
    return response;
};

export const getBlogById = async (id) => {
    const response = await axiosInstance.get(`/api/blogs/${id}`);
    return response;
};

export const createBlog = async () => {
    const response = await axiosInstance.post('/api/blogs');
    return response;
};

export const updateBlog = async (id, updatedBlog) => {
    const response = await axiosInstance.put(`/api/blogs/${id}`, updatedBlog);
    return response;
};

export const deleteBlog = async (id) => {
    const response = await axiosInstance.delete(`/api/blogs/${id}`);
    return response;
};

export const downloadFile = async (fileName) => {
    const response = await axiosInstance.get(`/api/files/${fileName}`, {
        responseType: "blob"
    });
    return response;
}
