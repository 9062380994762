import axios from 'axios'

const baseURL = process.env.REACT_APP_BASE_URL
let headers = {}

if (localStorage.token) { headers.Authorization = `Bearer ${localStorage.token}` }

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers
});

axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        const { status, data } = error.response;
        if (status === 400) {
            throw new Error(data.error);
        } else if (status === 401) {
            throw new Error('Unauthorized');
        } else if (status === 404) {
            throw new Error('Not found');
        } else {
            throw new Error('Something went wrong');
        }
    }
);

export default axiosInstance;

// Usage :
// import AxiosInstance from 'axios-helper';
// axiosInstance.post('/home')
//     .then(res=>console.log(res))
//     .catch(err=>console.log(err))
