import React, {Component} from 'react';
import Reveal from "react-reveal/Reveal";
import {t} from "i18next";

class AgencyAction extends Component {
    render(){
        let FooterData = this.props.FooterData;
        return(
            <section className="action_area_three sec_pad">
                {/*<div className="curved"></div>*/}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="action_content text-center">
                                {/*<h2 className="f_600 f_size_30 l_height45 mb_40">Ready to enjoy SaasLand and expenses?</h2>*/}
                                {/*<p className="f_400 f_size_18" align="justify" style={{color: "white"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
                            {/*    <a href=".#" className="about_btn white_btn wow fadeInLeft" data-wow-delay="0.3s">Just SaasLand IT</a>*/}
                            {/*    <a href=".#" className="about_btn wow fadeInRight" data-wow-delay="0.4s">Request a Demo</a>*/}
                                {
                                    FooterData.SocialLinks.map(widget=>{
                                        return(
                                            <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                                <div className="col-lg-12 col-md-6">
                                                    <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                                        <h3 className="f-title f_600 t_color f_size_18">{t("Urmareste - ne si pe:")}</h3>
                                                        <div className="f_social_icon">
                                                            {
                                                                widget.menuItems.map(item =>{
                                                                    return(
                                                                        <a href={item.url} target="_blank" key={item.id}><i className={item.icon}></i></a>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Reveal>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AgencyAction;
