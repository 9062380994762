import React, {useEffect} from 'react';
import { changeLanguage } from '../i18n';
import {useTranslation} from "react-i18next";
import {useAtom} from "jotai";
import {languageAtom} from "../state";
import roFlag from "../img/flags/ro.svg"
import enFlag from "../img/flags/gb.svg"

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useAtom(languageAtom);
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setCurrentLanguage(language);
    };

    return (
        <div>
            {currentLanguage === 'ro' ? (
                // <button onClick={() => changeLanguage('en')}><img src={enFlag} width="30px" height="30px"></img></button>
                <img src={enFlag} width="30px" height="30px" onClick={() => changeLanguage('en')} style={{cursor : "pointer"}}></img>
            ) : (
                // <button onClick={() => changeLanguage('ro')}><img src={roFlag} width="30px" height="30px"></img></button>
                <img src={roFlag} width="30px" height="30px" onClick={() => changeLanguage('ro')} style={{cursor : "pointer"}}></img>
            )}
        </div>
    );
};

export default LanguageSelector;
