import React from 'react';
import Sectitle from '../Title/Sectitle'
import {t} from "i18next";

const Designtab =() => {
    return(
        <section className="design_tab_area sec_pad" style={{paddingTop: "0px"}}>
            <div className="container">
                <Sectitle sClass="sec_title text-center mb_70" Title='MEDICAL ACT FOR IMPACT by MedCorp' TitleP={t('Implica-te activ in programe si campanii de asistenta medicala preventiva cuprinzatoare, sistematica si tintita, care genereaza maximum de beneficii pentru sanatatea grupului tinta cu maximum de eficienta in utilizarea resurselor.')}/>
                <div className="row">
                    <div className="col-lg-4 d-flex align-items-center">
                        <ul className="nav nav-tabs design_tab support_tab" role="tablist">
                            <li className="nav-item wow fadeInUp" data-wow-delay="0.4s">
                                <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true">
                                    <h5>{t("Campanii de sanatate 360")}</h5>
                                </a>
                            </li>
                            <li className="nav-item wow fadeInUp" data-wow-delay="0.6s">
                                <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">
                                    <h5>{t("Screening-uri riscuri specifice")}</h5>
                                    {/*<p>Design to development,<br/> automated</p>*/}
                                </a>
                            </li>
                            <li className="nav-item wow fadeInUp" data-wow-delay="0.8s">
                                <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">
                                    <h5>{t("Programe de monitorizare si evaluare continua")}</h5>
                                    {/*<p>Design to development,<br/> automated</p>*/}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-8">
                        <div className="tab-content support_tab_content">
                            <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                                <div className="tab_img">
                                    <img src={require ("../../img/new-home/campanii 02.png")} alt=""/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div className="tab_img">
                                    <img src={require ("../../img/new-home/screeninguri 02.png")}/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                                <div className="tab_img">
                                    <img src={require ("../../img/new-home/programe 02.png")}alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Designtab;
