import Sectitle from "../Title/Sectitle";
import React from 'react';
import {t} from "i18next";

const CopyOfServiceDetails = () => {
    return (
        <>
            <section className="service_details_area sec_pad" style={{paddingBottom: "0px"}}>
                <div className="container">
                    <Sectitle Title="" TitleP=""
                              tClass="t_color3" sClass="sec_title text-center mb_70"/>
                    <div className="row">
                        <div className="col-lg-5 pr_70 d-flex align-items-center">
                            <div className="web_img">
                                <img src={require('../../img/home-chat/Picture1.jpg')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="details_content">
                                <div className="sec_title">
                                    <p className="f_400 f_size_14" align="justify">
                                        {t('Pandemia de COVID-19 este un memento nedorit despre cat de mult conteaza sanatatea pentru indivizi, societate si economia globala. O sanatate mai buna promoveaza cresterea economica prin extinderea fortei de munca si prin cresterea productivitatii, oferind in acelasi timp beneficii sociale imense.')}
                                        <span style={{color: "#0090cc"}}>{t('Economistii estimeaza ca aproximativ o treime din cresterea economica din economiile avansate din ultimul secol ar putea fi atribuita imbunatatirii sanatatii populatiei.')}</span>
                                    </p>
                                    <p className="f_400 f_size_14" align="justify">
                                        <span style={{color: "#0090cc", paddingLeft: "0px"}}>{t("Peste 70% din castigurile in materie de sanatate ar putea fi obtinute din prevenire")}</span>
                                        {t("prin crearea unor medii mai curate si mai sigure, incurajand comportamente mai sanatoase si abordand factorii sociali care stau in spatele acestora, precum si extinderea accesului la vaccinuri si medicina preventiva. Restul de 30% ar proveni din tratarea bolilor si a afectiunilor acute cu terapii dovedite, inclusiv medicamente si interventii chirurgicale.")}
                                    </p>
                                    <p className="f_400 f_size_14" align="justify">
                                        {t("Realizarea beneficiilor ar insemna transferarea cheltuielilor catre prevenire.")}
                                        <span style={{color: "#0090cc"}}>{t("Prevenirea bolilor este de obicei mai putin costisitoare decat tratamentul si reduce necesitatea unui tratament mai scump ulterior, contribuind la un randament economic ridicat.")}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default CopyOfServiceDetails;
