import React from 'react';
import {t} from "i18next";

const EventDetails =()=>{
    return(
        <section className="event_promotion_area" style={{paddingTop: "0px"}}>
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">{t("Iti place voluntariatul si vrei sa te implici?")}</h2>
                </div>
                <div className="event_promotion_inner">
                    <div className="event_img">
                        <img src={require('../../img/home-event/showing-romance-call-suit-profes.jpg')} alt=""/>
                    </div>
                    <div className="row event_promotion_info align-items-center">
                        <div className="col-md-6">
                            <div className="e_promo_text wow fadeInDown">
                                <div className="d-flex">
                                    {/*<a href="/#" className="promo_tag">Workshop</a>*/}
                                    {/*<a href="/#" className="date">24 - 06 - 2019</a>*/}
                                </div>
                                {/*<br/><br/>*/}
                                <br/>
                                <h4 align="justify">{t("Devino voluntar Medcorp!")}</h4>
                                <br/>
                                <h5>{t("Scrie-ne la adresa: ")}<a href="mailto:radu.prodan@medcorp.ro">radu.prodan@medcorp.ro</a></h5>
                                <h5>{t("sau suna-ne la telefon: ")}</h5>
                                <h5><a href="tel:+40722632414">+40 722 632 414</a></h5>
                                <br/>
                                {/*<a href="/#" className="event_btn event_btn_two btn_hover">Buy Tickets</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 align-self-end">

                            <p>{t("Ce face un voluntar MEDCORP? In functie de experienta:")}</p>
                            <ul>
                                <li>{t("Gestioneaza lista de beneficiari si programari pe timpul Caravanei")}</li>
                                <li>{t("Primeste si inregistreaza beneficiarii la Caravana")}
                                </li>
                                <li>{t("Asista beneficiarii in procesul de completare a documentelor/ chestionarelor")}
                                </li>
                                <li>{t("Asista medicii in timpul consulturilor si completeaza fisele")}
                                </li>
                                <li>{t("Efectueaza analize de sange pacientilor")}
                                </li>
                                <li>{t("Efectueaza examene clinice si EKG")}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default EventDetails;
