import React from 'react';
import StartupFeatures from "../../components/Features/StartupFeatures";
import Navbar from "../Navbar";
import AgencyBanner from "../../components/Banner/AgencyBanner";
import BannerData from "../../components/Banner/BannerData";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import AgencyAction from "../../components/AgencyAction";
import {t} from "i18next";

const ProgrameCampanii = () => {
    return (
        <div>
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title={t("Medical act")} title1={t("for impact")} description="" classBG="formare_antreprenoriala"/>

            <StartupFeatures/>
            <AgencyAction FooterData={FooterData}/>
            <FooterTwo FooterData={FooterData}/>

        </div>
    );
};

export default ProgrameCampanii;
