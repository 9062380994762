import React, {Component} from 'react';
import Reveal from 'react-reveal';
import {t} from "i18next";
class StartupFeatures extends Component {
    render(){
        return(
            <section className="startup_fuatures_area sec_pad">
                <div className="container">
                    <div className="sec_title mb_70 wow fadeInUp" data-wow-delay="0.4s">
                    <Reveal effect="fadeInLeft"><h2 className="f_p f_size_30 l_height40 f_600 t_color text-center">{t("Concepem si derulam")} <br/>{t("Programe si campanii 100% customizabile")}</h2></Reveal>
                    </div>
                    <ul className="nav nav-tabs startup_tab" id="myTab" role="tablist">
                        <Reveal effect="fadeInLeft">
                            <li className="nav-item">
                            <a className="nav-link active" id="market-tab" data-toggle="tab" href="#market" role="tab" aria-controls="market" aria-selected="true">
                                <span><img src={require ('../../img/new/pachet 1 icon.png')} width="70px" height="70px" alt=""/></span>
                                <h3>{t("(Co)finanteaza o Campanie de sanatate 360")}</h3>
                            </a>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={1400}>
                            <li className="nav-item">
                                <a className="nav-link" id="app-tab" data-toggle="tab" href="#app" role="tab" aria-controls="app" aria-selected="false">
                                    {/*<span className="icon"><i className="icon-screen-tablet"></i></span>*/}
                                    <span><img src={require ('../../img/new/pachet 2 icon.png')} width="70px" height="70px" alt=""/></span>
                                    <h3>{t("(Co)finanteaza o Campanie de screening(-uri)")}</h3>
                                </a>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2000}>
                            <li className="nav-item">
                                <a className="nav-link" id="hubstaff-tab" data-toggle="tab" href="#hubstaff" role="tab" aria-controls="hubstaff" aria-selected="false">
                                    <span><img src={require ('../../img/new/pachet 3 icon.png')} width="70px" height="70px" alt=""/></span>
                                    <h3>{t("Comanda un studiu sau un program de monitorizare Medcorp")}</h3>
                                </a>
                            </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2400}>
                        <li className="nav-item">
                            <a className="nav-link" id="engine-tab" data-toggle="tab" href="#engine" role="tab" aria-controls="engine" aria-selected="false">
                                <span><img src={require ('../../img/new/pachet 4 icon.png')} width="70px" height="70px" alt=""/></span>
                                <h3>{t("Implica - te intr-o campanie de educatie MedCorp")}</h3>
                            </a>
                        </li>
                        </Reveal>
                        <Reveal effect="fadeInLeft" duration={2800}>
                        <li className="nav-item">
                            <a className="nav-link" id="business-tab" data-toggle="tab" href="#business" role="tab" aria-controls="business" aria-selected="false">
                                <span><img src={require ('../../img/new/pachet 5 icon.png')} width="70px" height="70px" alt=""/></span>
                                <h3 >{t("Sprijina un program de Wellness MedCorp")}</h3>

                            </a>
                        </li>
                        </Reveal>
                    </ul>
                    <div className="tab-content startup_tab_content" id="myTabContent">
                        <div className="tab-pane fade show active" id="market" role="tabpanel" aria-labelledby="market-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={require ('../../img/new/poza pachet 1 (1).png')} alt=""/>
                                </div>
                                <div className="phone_img"><img src={require ('../../img/new/logo pachete.png')} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="app" role="tabpanel" aria-labelledby="app-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={require ('../../img/new/poza pachet 2 (1).png')} alt=""/>
                                </div>
                                <div className="phone_img"><img src={require ('../../img/new/logo pachete.png')} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="hubstaff" role="tabpanel" aria-labelledby="hubstaff-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={require ('../../img/new/poza pachet 3 (1).png')} alt=""/>
                                </div>
                                <div className="phone_img"><img src={require ('../../img/new/logo pachete.png')} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="engine" role="tabpanel" aria-labelledby="engine-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={require ('../../img/new/poza pachet 4 (1).png')} alt=""/>
                                </div>
                                <div className="phone_img"><img src={require ('../../img/new/logo pachete.png')} alt=""/></div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="business" role="tabpanel" aria-labelledby="business-tab">
                            <div className="startup_tab_img">
                                <div className="web_img">
                                    <img src={require ('../../img/new/poza pachet 5 (1).png')} alt=""/>
                                </div>
                                <div className="phone_img"><img src={require ('../../img/new/logo pachete.png')} alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default StartupFeatures;
