import React, {useEffect, useState} from 'react';
import Blogrightsidebar from './Blogrightsidebar';
import BlogGridItem from './BlogGridItem';
import ServiceData from '../Service/ServiceData';
import {getAllBlogs} from "../../Pages/requestFunctions";
import {useAtom} from "jotai";
import {currentBlogs} from "../../state";
import {t} from "i18next";

const BlogGrid = () =>{

    // const [blogs, setBlogs] = useAtom(currentBlogs.blogs);
    // const [isLoading, setIsLoading] = useState(true);
    // useEffect(() => {
    //     getAllBlogs()
    //         .then((response) => {
    //             setBlogs(response.data)
    //             setIsLoading(false)
    //         })
    //         .catch((error) => console.log(error))
    // }, [])

    // if (isLoading) return <></>;
    return(
        <section className="blog_area_two sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_grid_info">
                        <div className="row">
                            {/*{blogs && blogs.map((blog) => {*/}
                            {/*    console.log(blog.title)*/}
                            {/*    return (*/}
                            {/*        // <BlogGridItem date="14"*/}
                            {/*        //               month="jan"*/}
                            {/*        //               image="49573 (1).jpg"*/}
                            {/*        //               Title="Despre Varsta si sex in medicina preventiva"*/}
                            {/*        //               description="Varstele cu semnificatie in preventie pentru un adult sunt: 19 ani, 40 de ani, 50 de ani, 65 de ani. "*/}
                            {/*        //               btn="Read More"*/}
                            {/*        //               comment="3"*/}
                            {/*        //               key={blog.id}*/}
                            {/*        // />*/}
                            {/*        <p>{blog.title}</p>*/}
                            {/*    )*/}
                            {/*})};*/}
                            <BlogGridItem blogId="1" date="14" month="jan" image="49573 (1).jpg" Title="Despre Varsta si sex in medicina preventiva" description="Varstele cu semnificatie in preventie pentru un adult sunt: 19 ani, 40 de ani, 50 de ani, 65 de ani. "
                            btn={t("Citeste mai mult")} comment="3"/>
                            <BlogGridItem blogId="2" date="15" month="Apr" image="Tiny doctor and heart of patient.jpg" Title="Hipertensiunea arteriala" description="Tensiunea arterială se referă la presiunea pe care sângele o exercită asupra pereților arterelor..."
                            btn={t("Citeste mai mult")} comment="2"/>

                        </div>
                        <ul className="list-unstyled page-numbers shop_page_number text-left mt_30">
                            {/*<li><span aria-current="page" className="page-numbers current">1</span></li>*/}
                            {/*<li><a className="page-numbers" href=".#">2</a></li>*/}
                            {/*<li><a className="next page-numbers" href=".#"><i className="ti-arrow-right"></i></a></li>*/}
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <Blogrightsidebar ServiceData={ServiceData}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BlogGrid;
