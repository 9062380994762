import React, {Component} from 'react';
import {t} from "i18next";

class HostingAction extends Component {
    render(){
        let {aClass, aimage, colClass, colClassTwo, Atitle, Atext}= this.props;
        return(
            <section className={`${aClass}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className={`${colClass}`}>
                            <div className="h_action_img">
                                <img className="img-fluid" src={require ("../img/hosting/" + aimage)} alt=""/>
                            </div>
                        </div>
                        <div className={`${colClassTwo}`}>
                            <div className="h_action_content">
                                {/*<h2>{Atitle}</h2>*/}
                                {/*<p>{Atext}</p>*/}
                                {/*<a href="/#" className="hosting_btn btn_hover">Product Docs</a>*/}
                                <p align="justify"><strong>{t('Asociatia Medcorp este o organizatie non-guvernamentala infiintata in anul 2021 cu scopul de a facilita accesul la servicii medicale moderne pentru toate categoriile de beneficiari inclusiv pentru categoriile “vulnerabile”.')}</strong> </p>
                                <p align="justify"><strong>{t("Organizam maratoane dedicate sanatatii, evaluari medicale specifice pe anumite specialitati, proiecte medicale educative pentru adulti sau copii, studii cantitative si calitative profesionale.")}</strong> </p>
                                <p align="justify"><strong>{t("Avem o retea extinsa de parteneri si colaboratori impreuna cu care concepem programe si campanii customizate si gestionam integral implementarea si desfasurarea cu success a acestora.")}</strong> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HostingAction;
