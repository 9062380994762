import React, { Component } from "react";

class EventFeaturesItem extends Component {
    render(){
        let{url, title, p1, p2, p3, p4, p5, btn, image}=this.props;
        return(
            <div className="col-lg-4 col-sm-6" style={{paddingBottom: "30px"}}>
                <div className={`event_features_item wow fadeInUp ${url}`}>
                    {image !== "" && (
                        <img src={require ("../../img/home-event/" + image)} alt="" width="40px" height="40px"/>

                    )}
                    <h6>{title}</h6>
                    <p align="justify"><b style={{color: "#065ea5"}}>{p1}</b>{p2}</p>
                    <p>{p3}</p>
                    <p>{p4}</p>
                    <p>{p5}</p>
                </div>
            </div>
        )
    }
}

export default EventFeaturesItem;
