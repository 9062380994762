import React from 'react';
import AgencyBanner from "../../components/Banner/AgencyBanner";
import BannerData from "../../components/Banner/BannerData";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import Navbar from "../Navbar";
import AppFeaturesTwo from "../../components/Features/AppFeaturesTwo";
import AppTeam from "../../components/Team/AppTeam";
import EventSponser from "../../components/EventSponser";
import EventDetails from "../../components/Features/EventDetails";
import AgencyAction from "../../components/AgencyAction";
import {t} from "i18next";

const EchipaMedcorp = () => {

    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title={t("O comunitate de specialisti cu capabilitati extinse si acoperire nationala")} description="" classBG="formare_antreprenoriala"/>

            <AppFeaturesTwo />
            <AppTeam />
            <EventSponser />
            <EventDetails/>
            <AgencyAction FooterData={FooterData}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
};

export default EchipaMedcorp;
