import React from "react";
import {NavLink} from "react-router-dom";

import EventSponserLogo from "./EventSponserLogo";
import {t} from "i18next";

const EventSponser = () => {
  return (
    <section className="event_sponser_area sec_pad">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2>
            {t("Impreuna avem impact mai mare")}
          </h2>
        </div>
        <div className="event_sponser_inner">
          <div className="event_sponser_item">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <h4 className="h_head sponser_title">{t("Sponsori")}</h4>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="row">
                  <EventSponserLogo url=".#" image="OMV_petrom.jpg" />
                  {/*<EventSponserLogo url=".#" image="logo_02.png" />*/}
                </div>
              </div>
            </div>
          </div>
          <div className="event_sponser_item">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <h4 className="h_head sponser_title">{t("Parteneri Strategici")}</h4>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="row">
                  {/*<EventSponserLogo url=".#" image="logo_05.png" />*/}
                  {/*<EventSponserLogo url=".#" image="logo_06.png" />*/}
                  {/*<EventSponserLogo url=".#" image="logo_07.png" />*/}
                </div>
              </div>
            </div>
          </div>
          <div className="event_sponser_item">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <h4 className="h_head sponser_title">{t("Parteneri Operationali")}</h4>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="row">
                  <EventSponserLogo url=".#" image="ConsiliulJudeteanP.png" />
                  <EventSponserLogo url=".#" image="diamedix.png" />
                  <EventSponserLogo url=".#" image="HopeMedicalCenter.png" />
                  <EventSponserLogo url=".#" image="DrLeventer.png" />
                  {/*<EventSponserLogo url=".#" image="logo_05.png" />*/}
                  {/*<EventSponserLogo url=".#" image="logo_06.png" />*/}
                  {/*<EventSponserLogo url=".#" image="logo_07.png" />*/}
                </div>
              </div>
            </div>
          </div>
          <div className="event_sponser_item text-center">
            {/*<a*/}
            {/*  className="event_btn btn_hover wow fadeInLeft"*/}
            {/*  data-wow-delay="0.9s"*/}
            {/*  href="/#"*/}
            {/*>*/}
            {/*  More Sponsors*/}
            {/*</a>*/}
            <NavLink
              className="event_btn event_btn_two btn_hover wow fadeInRight"
              data-wow-delay="0.9s"
              to="/Contact"
            >
              {t("Implica-te")}
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventSponser;
