import React from 'react';
import Sectitle from "./Title/Sectitle";
import {t} from "i18next";

const chatClients =()=>{
    return(
        <div style={{paddingBottom: "100px"}}>
            <section className="chat_clients_area mt_100">
                <div className="container-fluid">
                    <Sectitle Title={t("Disparitatile in ceea ce priveste accesul la asistenta medicala sunt persistente:")}
                              TitleP={t("Peste 70% din persoanele din mediul rural declara ca nu isi permit sa participle la un consult medical de specialitate.")}
                              tClass="t_color3" sClass="sec_title text-center mb_70"/>

                    <div className="row">
                        <div className="col-lg-6 p-0 chat_clients_feedback" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="clients_feedback_item flex-row-reverse" >
                                <div className="feedback_body" >
                                    <h3 className="f_name">{t("Directia 1")}</h3>
                                    {/*<a href=".#">hello.com</a>*/}
                                    <p align="justify">
                                        {t("Intampinarea unor probleme medicale pe care cetatenii din mediile defavorizate deja le infatiseaza si punerea la dispozitie de servicii medicale gratuite, prin facilitarea acestor servicii in proximitate, pe o perioada de timp prestabilita.")}
                                    </p>
                                    </div>
                                <img src={require("../img/home-chat/directia 1.png")} width="240" height="auto" alt="" style={{display: "flex", margin: "0 auto"}}/>
                            </div>
                        </div>
                        <div className="col-lg-6 p-0 chat_clients_feedback feedback_two" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="clients_feedback_item">
                                <div className="feedback_body" >
                                    <h3 className="f_name">{t("Directia 2")}</h3>
                                    {/*<a href=".#">hello.com</a>*/}
                                    <p align="justify">
                                        {t("Preintampinarea unor probleme de sanatate pe care locuitorii mediilor defavorizate le-ar putea dezvolta, depistarea eventualelor probleme de sanatate pe acestia le au.")}
                                    </p>
\                               </div>
                                <img src={require("../img/home-chat/directia 2 alb.png")} width="240" height="auto" alt="" style={{display: "flex", margin: "0 auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section className="chat_get_started_area">*/}
            {/*    <div className="container">*/}
            {/*        <div className="chat_get_started_content text-center">*/}
            {/*            <h2 className="wow fadeInUp" data-wow-delay="0.2s">Get started with us</h2>*/}
            {/*            <p>A better experience for your customers, fewer headaches for your team. You'll be set up in minutes.</p>*/}
            {/*            <a href=".#" className="chat_btn btn_hover">Get Started</a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </div>
    )
}
export default chatClients;
