import React from 'react';
import EventFeaturesItem from './EventFeaturesItem';
import Sectitle from "../Title/Sectitle";
import {t} from "i18next";

const EventFeatures =()=>{
    return(
        <section className="event_features_area" style={{paddingBottom: "150px"}}>
            <div className="container" >
                <div className="hosting_title security_title text-center">
                    <Sectitle Title={t("ABORDAREA MEDCORP")} TitleP=""
                              tClass="t_color3" sClass="sec_title text-center"/>
                </div>
                <div className="row event_features_inner">
                    <EventFeaturesItem url="bg_image_abordare"
                                       image=""
                                       title=""
                                       p1=""
                                       p2=""
                                       p3=""
                                       p4=""
                                       p5=""
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="icon_inlaturam bariera_v2.png"
                                       title=""
                                       p1={t("Inlaturam bariera accesului la asistenta medicala.")}
                                       p2={t("Ne deplasam in teritoriu, impreuna cu personal specializat, pentru a acorda servicii medicale si consultatii multidisciplinare acolo unde este nevoie de ele. Sprijinim proactiv solutionarea cazurilor grave care necesita investigatii/ interventii suplimentare.")}
                                       p3=""
                                       p4=""
                                       p5=""
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="icon_asiguram furnizarea_v2.png"
                                       title=""
                                       p1={t("Asiguram furnizarea de servicii si informatii legate de sanatate utilizand tehnologiile informatiei si comunicatiilor ")}
                                       p2={t("ca un motor esential pentru extinderea accesului la servicii si promovarea continuitatii ingrijirii. Programele noastre extinse pot include follow-up si servicii de monitorizare a evolutiei pe termen mediu si lung. ")}
                                       p3=""
                                       p4=""
                                       p5=""
                                       btn="Buy Tickets"/>

                    <EventFeaturesItem url=""
                                       image="icon_acordam atentie_v2.png"
                                       title=""
                                       p1={t("Acordam  atentie deosebita preventiei. ")}
                                       p2={t("Cu cat cauzele sau existenta unei boli se descopera inainte ca simptomele sa se faca simtite si afectiunea sa devina cronica, cu atat mai mult cresc sansele de vindecare. Programele noastre de Check-Up sustin diagnosticarea precoce care permite prevenirea dezvoltarii de afectiuni cronice si oprirea din evolutie a acestora in stadii incipiente.")}
                                       p3=""
                                       p4=""
                                       p5=""
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="icon_facilitam accesul_v2.png"
                                       title=""
                                       p1={t("Facilitam accesul la investigatii medicale de specialitate. ")}
                                       p2={t("Avem capabilitatile necesare pentru a asigura accesul la o serie completa de programe de screening, care includ controale destinate identificarii problemelor ascunse precum si a problemelor legate de durerile/ problemele specifice pe care le acuza pacientii.")}
                                       p3=""
                                       p4=""
                                       p5=""
                                       btn="Buy Tickets"/>
                    <EventFeaturesItem url=""
                                       image="icon_promovam activ_v2.png"
                                       title=""
                                       p1={t("Promovam activ medicina personalizata.")}
                                       p2={t(" Evaluam starea generala de sanatate a persoanei, factorii de risc pentru diferite boli care pot fi prevenite, efectuam teste de sange relevante si corelam rezultatele cu necesarul de investigatii si alte proceduri, rezultand un pachet de servicii adaptat fiecarei persoane, in functie de varsta, sex si riscul individual.")}
                                       p3=""
                                       p4=""
                                       p5=""
                                       btn="Buy Tickets"/>

                </div>
            </div>
        </section>
    )
}
export default EventFeatures;
