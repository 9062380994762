import React from 'react';
import Breadcrumb from "../../components/Breadcrumb";
import SignInForm, {userAtom} from "../../components/SignInForm";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import Navbar from "../Navbar";

const PaginaLogin = () => {

    return (
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Conectare la platforma" Pdescription=""/>
            <SignInForm/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    );
};

export default PaginaLogin;
