import React , {Component} from 'react';
import {t} from "i18next";

class FooterTwo extends Component {
    render(){
        var {fClass} = this.props;
        let FooterData = this.props.FooterData;
        return(
            <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-5 col-sm-6 align-items-center">
                                <p className="mb-0 f_400" align="center">{t("Copyright © 2023. Website detinut de ASOCIATIA MEDCORP. Toate drepturile sunt rezervate.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterTwo;
