import React from "react";
import {t} from "i18next";

const EventFact = () => {
  return (
    <section className="event_fact_area sec_pad">
      <div className="container">
        <div align="justify">
          <h2 align="center">
            {t("Caravana de servicii medicale sustinuta de OMV-PETROM")}
          </h2>
          <br/><br/>
          <p>{t("Cu ajutorul a peste 120 de voluntari cadre medicale, rezidenți, studenți, mobilizarea în 2022 a fost canalizată pe 10 vizite în 5 localități din Prahova, oferind controale medicale gratuite în locățiile puse la dispoziție de primării (de regulă gradinițe și școli). Voluntarii și membri asociației Medcorp și-au dedicat câte 2 zile din weekend pentru fiecare localitate, oferind analize de sânge complete, în medie pentru 100 persoane în fiecare localitate. În următoarea săptămână, după înregistrare, pacienții au fost verificați prin examene clinice și EKG, după care au fost direcționati către consultații specializate (medicină internă, cardiologie, ecografie, dermatologie, oftalmologie). De fiecare dată au fost aduse aparate performante chiar și rulotă ambulatorie cardio, astfel încât fiecare locație s-a transformat într-un mini spital.")}</p>
        </div>
        <div className="seo_fact_info">
          <div className="seo_fact_item wow fadeIn">
            <div className="text">
              <div className="counter one">10</div>
              <p>{t("Zile")}</p>
            </div>
          </div>
          <div className="seo_fact_item wow fadeIn" data-wow-delay="0.3s">
            <div className="text">
              <div className="counter two">5</div>
              <p>{t("Locatii")}</p>
            </div>
          </div>
          <div className="seo_fact_item wow fadeIn" data-wow-delay="0.6s">
            <div className="text">
              <div className="counter three">500</div>
              <p>{t("Beneficiari")}</p>
            </div>
          </div>
          <div className="seo_fact_item last wow fadeIn" data-wow-delay="0.9s">
            <div className="text">
              <div className="counter four">2646</div>
              <p>{t("Interventii")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventFact;
