import React, {Component} from 'react';
import Reveal from 'react-reveal'
import {t} from "i18next";
class HRServiceItems extends Component{
    render(){
        var{HRtitle, HRdescription, Hicon, rclass, iclass, l1, l2, l3, l4, textColor} = this.props;
        return(
                <div className="col-lg-4 col-sm-6">
                    {/*<Reveal bottom cascade>*/}
                    <div className={`p_service_item ${rclass}`}>
                        <div className={`icon ${iclass}`}><i className={`ti-${Hicon}`}></i></div>
                        {l3 === "" && (
                            <>
                                <h5 className="f_600 f_p t_color3" style={{color: "red"}}>{t(HRtitle)}</h5>
                                <p className="f_400" style={{marginBottom: '0', color: "red"}}>{t(l1)}}</p>
                                <p className="f_400" style={{marginBottom: '0', color: "red"}}>{t(l2)}</p>
                                <p className="f_400" style={{marginBottom: '0'}}>{t(l3)}</p>
                                <p className="f_400" style={{marginBottom: '0'}}>{t(l4)}</p>
                            </>

                        )}
                        {l3 !== "" && (
                            <>
                                <h5 className="f_600 f_p t_color3" style={{color: textColor}}>{t(HRtitle)}</h5>
                                <p className="f_400" style={{marginBottom: '0', color: textColor}}>{t(l1)}</p>
                                <p className="f_400" style={{marginBottom: '0', color: textColor}}>{t(l2)}</p>
                                <p className="f_400" style={{marginBottom: '0', color: textColor}}>{t(l3)}</p>
                                <p className="f_400" style={{marginBottom: '0', fontSize: "10px", color: textColor}}><i>{t(l4)}</i></p>
                            </>
                        )}

                    </div>
                    {/*</Reveal>*/}
                </div>

        )
    }
}
export default HRServiceItems;
