import React, {useEffect, useState} from 'react';
import Sticky from "react-stickynode";
import {Link, NavLink, Redirect} from "react-router-dom";
// import {useAtom} from "jotai";
// import {userAtom} from "../components/SignInForm";
import Scroll, {scroller} from 'react-scroll';
import {getJwtSub} from "../components/Helper/jwt_helper";
import {currentUser} from "../state";
import {useAtom} from "jotai";
import {useTranslation} from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import {t} from "i18next";


const Navbar = ({mClass, nClass, cClass, hbtnClass, slogo}) => {
    const [user, setUser] = useAtom(currentUser.user);
    const scrollTarget = (target) => scroller.scrollTo(target, {smooth: true, duration: 0, spy: true});
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
    const scrollToHomePage = async (target) => {
        // if (window.location.pathname !=='/') {
        //     // eslint-disable-next-line no-restricted-globals
        //     // await history.push("/");
        //     await (window.location.href = '/')
        //     await delay(100);
        // }
        scrollTarget(target);
    };

    function handleLogout() {
        setUser(null);
        localStorage.clear();
    }

    return (

        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
            <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo_1.png")} width="246px" height="89" alt=""/>
                            <img src={require("../img/logo_1.png")} width="246px" height="89" alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                                    <NavLink exact to='/' className="nav-link" >
                                        {t("Despre noi")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Programe-Campanii">
                                        {t("Programe & Campanii")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/Studii-Statistica' className="nav-link">
                                        {t("Studii & Statistica")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Calendar-Evenimente">
                                        {t("Calendar")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/Echipa-Medcorp' className="nav-link">
                                        {t("Echipa Medcorp")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Noutati">
                                        {t("Noutati")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Contact">
                                        {t("Contact")}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <LanguageSelector />
                                </li>
                                {/*<li className="nav-item"><NavLink className="nav-link" to="/Pagina-Lucru">Pagina Lucru</NavLink></li>*/}
                                {/*{user === null && (*/}
                                {/*    <li className="nav-item">*/}
                                {/*        <Link className={`btn_get btn_hover`} to="/login">Login</Link>*/}
                                {/*    </li>*/}
                                {/*)}*/}
                                {/*{user !== null && (*/}
                                {/*    <li className="nav-item" onClick={handleLogout}>*/}
                                {/*        <Link className={`btn_get btn_hover ${hbtnClass}`} to="#" >Logout</Link>*/}
                                {/*    </li>*/}
                                {/*)}*/}
                            </ul>

                        </div>
                    </div>
                </nav>
            </header>
         </Sticky>
    );
};

export default Navbar;
