import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Featuresitems from '../components/Features/Featuresitems';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Sectitle from "../components/Title/Sectitle";
import {t} from "i18next";
const Process = () => {
    return(
        <div className="body_wrapper">
            {/*<CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>*/}
            {/*<Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="OBIECTIVE SPECIFICE ALE PROIECTULUI" Pdescription=""/>*/}

            <section className="process_area bg_color sec_pad" style={{paddingBottom: "0px"}}>
                <div className="container">
                    <div className="hosting_title security_title text-center">
                        {/*<h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>AJOFM-uri partenere</span></h2>*/}
                        <Sectitle Title={t("OBIECTIVE PRINCIPALE")} TitleP="" tClass="t_color3" sClass="sec_title text-center"/>
                        {/*<p align="justify">In vederea mobilizarii tinerilor NEETs deja inregistrati la SPO in bazele de date ANOFM/AJOFM spre a se inscrie in grupul tinta, se vor incheia acorduri cadru de parteneriat cu AJOFM-uri din judetele care fac parte din regiunea Sud Muntenia:</p>*/}
                    </div>
                    <div className="features_info">
                        {/*<img className="dot_img" src={require ('../img/home4/divider.png')} alt=""/>*/}

                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="mentinem sanatatea.png" iImg="icon01.png" ftitle={t("Mentinem sanatatea pe agenda tuturor")} descriptions={t("Prevenirea pe termen lung si promovarea sanatatii nu pot fi lasate doar in sarcina furnizorilor de asistenta medicala sau a sistemelor de sanatate publica. Este practic nevoie de implicarea tuturor. Promovarea comunitatilor sanatoase si a locurilor de munca sanatoase si incluzive este o prioritate strategica esentiala pentru toate organizatiile cu rol activ in mediul economic.")}/>
                        <Featuresitems rowClass="row" aClass="pl_100" fimage="Contact.jpg" iImg="icon02.png" ftitle={t("Contribuim activ la Transformarea sistemului de sanatate ")} descriptions={t("Provocarea consta in identificarea si implementarea schimbarilor care faciliteaza tranzitia catre sanatatea preventiva. Acest lucru implica ingrijire primara de inalta calitate si o abordare holistica a actului medical care sa tina cont de nevoile specifice grupurilor tinta; diversificarea canalelor de livrare a serviciilor medicale este o solutie concreta pentru a ajunge la oameni cand si unde este cel mai probabil sa beneficieze de ele.")}/>
                        <Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="promovam sanatatea digitala.png" iImg="icon3.png" ftitle={t("Promovam sanatatea digitala")} descriptions={t("Instrumentele digitale pot imbunatati echitatea prin cresterea accesului la asistenta medicala, abordand nevoile “nesatisfacute” si personalizand ingrijirea pacientilor. Provocarile coplesitoare de sanatate publica ale pandemiei au catalizat utilizarea lor ca un mecanism esential nu numai pentru consolidarea raspunsului la pandemie, ci si pentru asigurare continuitatea serviciilor de sanatate. ")}/>
                        {/*<Featuresitems rowClass="row" aClass="pl_100" fimage="businessman-his-office-with-pilo.jpg" iImg="icon_04.png" ftitle="Sustinerea antreprenoriatului" descriptions="Sustinerea antreprenoriatului si stimularea demararii unei activitati independente pentru 15 tineri NEETs A cu abilitati si interese in domeniul antreprenorial din regiunea Sud Muntenia prin derularea unui program de sustinere antreprenoriala care include derularea unui curs acreditat ANC de competente antreprenoriale pentru 28 persoane, organizarea concursului de evaluare si selectare a celor 15 antreprenori, furnizarea de servicii de consiliere, consultanta si mentorat pentru acestia siacordarea a 15 subventii implementarea planurilor de afaceri."/>*/}
                        {/*<Featuresitems rowClass="row flex-row-reverse" aClass="pr_70 pl_70" fimage="process_5.png" iImg="icon_05.png" ftitle="With efficiency to unlock more opportunities"*/}
                        {/*descriptions="Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy cras tickety-boo squiffy. Starkers dropped a clanger lurgy is cack excuse my French what a plonker blower.!"/>*/}
                        {/*<div className="dot middle_dot"><span className="dot1"></span><span className="dot2"></span></div>*/}
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Process;
