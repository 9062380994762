import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class Teamitem extends Component {
    render(){
        var {teamImage, memberN, memberd, email, tel}= this.props;
        return(
            <div className="ex_team_item">
                <img src={require ("../../img/teams/" + teamImage)} alt="team" height="300px" width="100%"/>
                <div className="team_content">
                    <a href=".#">
                        <h3 className="f_p f_size_16 f_600 t_color3">{memberN}</h3>
                    </a>
                    <h5>{memberd}</h5>
                </div>
                <div className="hover_content">
                    <div className="n_hover_content">
                        {email === "" ?
                            <>
                                <NavLink to="/Contact">
                                    <h3 className="f_p f_size_16 f_600 w_color">Contact</h3>
                                </NavLink>
                                <div className="br"></div>
                            </>
                            :
                            <>
                                <a href={`mailto:${email}`}>
                                    <h3 className="f_p f_size_16 f_600 w_color">{email}</h3>
                                </a>
                                <div className="br"></div>
                                <h5>{tel}</h5>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default Teamitem;
