import React from 'react';
import Fade from 'react-reveal/Fade';
import {t} from "i18next";

const Service = () => {
    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <img src={require('../../img/seo/circles1.jpg')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 align="justify">{t("Concepem si derulam studii")}</h2>
                                    <p align="justify">{t("Asociatia Medcorp isi propune sa contribuie activ la elaborarea si implementarea unui model de medicina preventiva si curativa care sa asigure deopotriva o eficacitate clinica optima si o eficienta maxima a serviciilor profilactice furnizate populatiei, precum si un instrument valoros pentru implementarea politicilor adresate problemelor majore de sanatate publica.")}</p>
                                    <p align="justify">{t("Programele si campaniile noastre se bazeaza pe informatii si statistici nationale si internationale.")}</p>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Service;
