import React from 'react';
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import Navbar from "../Navbar";
import AgencyBanner from "../../components/Banner/AgencyBanner";
import BannerData from "../../components/Banner/BannerData";
import Contacts from "../../components/Contacts";
import AgencyAction from "../../components/AgencyAction";
import {t} from "i18next";

const ContactMedcorp = () => {
    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title={t("Implica-te activ ")} title1={t("in proiectele noastre")} title2="" description="" classBG="pagina_contact"/>
            <Contacts/>
            <AgencyAction FooterData={FooterData}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
};

export default ContactMedcorp;
