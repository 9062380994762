import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ScrollToTopRoute from './ScrollToTopRoute';
import NotFound from './Pages/404';
import DespreNoi from './Pages/DespreNoi/DespreNoi';
import StudiiStatistica from './Pages/Studii&Statistica/StudiiStatistica';
import EchipaMedcorp from './Pages/EchipaMedcorp/EchipaMedcorp';
import ContactMedcorp from './Pages/Contact/ContactMedcorp';
import Noutati from './Pages/Noutati/Noutati';
import PaginaLogin from './Pages/Login/PaginaLogin';
import CalendarEvenimente from './Pages/Calendar/CalendarEvenimente';
import ProgrameCampanii from './Pages/Programe&Campanii/ProgrameCampanii';
import Noutate from './Pages/Noutati/Noutate';
import Noutate2 from './Pages/Noutati/Noutate2';
import LanguageSelector from "./Pages/LanguageSelector";
import {useAtom} from "jotai";
import {languageAtom} from "./state";
import {atomWithStorage} from "jotai/utils";


const App = () => {
  const { t, i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useAtom(languageAtom);
  if (localStorage.getItem("language") === null) {
    setCurrentLanguage('ro')
  }

  return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact path="/" component={DespreNoi} />
          <ScrollToTopRoute path="/Studii-Statistica" component={StudiiStatistica} />
          <ScrollToTopRoute path="/Calendar-Evenimente" component={CalendarEvenimente} />
          <ScrollToTopRoute path="/Programe-Campanii" component={ProgrameCampanii} />
          <ScrollToTopRoute path="/Echipa-Medcorp" component={EchipaMedcorp} />
          <ScrollToTopRoute path="/Contact" component={ContactMedcorp} />
          <ScrollToTopRoute path="/Noutati" component={Noutati} />
          <ScrollToTopRoute path="/Noutati-1" component={Noutate} />
          <ScrollToTopRoute path="/Noutati-2" component={Noutate2} />
          <ScrollToTopRoute path="/login" component={PaginaLogin} />
          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
  );
};

export default App;
