import React from 'react';
import Navbar from "../Navbar";
import AgencyBanner from "../../components/Banner/AgencyBanner";
import BannerData from "../../components/Banner/BannerData";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import EventSchedule from "../../components/EventSchedule";
import AgencyAction from "../../components/AgencyAction";
import {t} from "i18next";

const CalendarEvenimente = () => {
    return (
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title={t("Venim in")} title1={t("comunitatea ta")} title2="" description="" classBG="calendar"/>
            <EventSchedule />
            <AgencyAction FooterData={FooterData}/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    );
};

export default CalendarEvenimente;
