import React from 'react';
import Blogrightsidebar from "../../components/Blog/Blogrightsidebar";
import ServiceData from "../../components/Service/ServiceData";

const BlogSingle2 = () => {
    return(
        <section className="blog_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={require('../../img/blog-grid/Tiny doctor and heart of patient.jpg')} alt=""/>
                            <div className="blog_content">
                                {/*<div className="post_date">*/}
                                {/*    <h2>14 <span>March</span></h2>*/}
                                {/*</div>*/}
                                {/*<div className="entry_post_info">*/}
                                {/*    By: <a href=".#">Admin</a>*/}
                                {/*    <a href=".#">2 Comments</a>*/}
                                {/*    <a href=".#">SaasLand</a>*/}
                                {/*</div>*/}

                                <h1 className="f_p f_size_30 f_500 t_color mb-30">HIPERTENSIUNEA ARTERIALA</h1>

                                <h1 className="f_p f_size_20 f_500 mb-30 text-center" style={{color: "#065ea5"}}>I TENSIUNEA ARTERIALA – INFORMATII GENERALE</h1>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Ce este tensiunea arteriala?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Tensiunea arteriala se refera la presiunea pe care sangele o exercita asupra peretilor arterelor pe masura ce inima pompeaza sange in tot corpul. Tensiunea arteriala este masurata in milimetri coloana de mercur (mmHg) si se exprima sub forma de doua numere, presiunea sistolica (reprezentand presiunea cand inima se contracta pentru a produce o bataie a inimii) si presiunea diastolica (reprezentand presiunea cand inima este in repaus intre doua batai).
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Cum se masoara corect tensiunea arteriala?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Pentru a obtine o valoare cat mai corecta atunci cand va monitorizati tensiunea arteriala, este necesar sa respectati cateva reguli pentru a evita ca rezultatele masurarii sa fie influentate de factori externi.
                                </p>
                                <p className="f_400 mb-0" align="justify">
                                    - INAINTE de masurarea tensiunii arteriale:
                                </p>
                                <p className="f_400 mb-0" align="justify">
                                    Cu 30 de minute inainte de masurarea tensiunii arteriale:
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">Nu fumati</li>
                                    <li className="f_400 mb-0" align="justify">Nu consumati cafeina</li>
                                    <li className="f_400 mb-0" align="justify">Nu faceti efort fizic</li>
                                </ul>
                                <p className="f_400 mb-0" align="justify">
                                    Cu 5 minute inainte de masurarea tensiunii arteriale:
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">Ramaneti in repaus, de preferat asezati pe pat sau scaun</li>
                                </ul>
                                <p className="f_400 mb-0" align="justify">
                                    - IN TIMPUL masurarii tensiunii arteriale
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">sigurati-va ca manseta tensiometrului are dimensiunea potrivita</li>
                                    <li className="f_400 mb-0" align="justify">Asigurati-va ca manseta tensiometrului este pozitionata in locul potrivit</li>
                                    <li className="f_400 mb-0" align="justify">Tineti bratul la nivelul inimii, de preferat pe o suprafata plana (de exemplu o masa)</li>
                                    <li className="f_400 mb-0" align="justify">Stai drept, cu spatele drept, cu picioarele pe podea</li>
                                    <li className="f_400 mb-0" align="justify">Nu vorbiti</li>
                                </ul>
                                <p className="f_400 mb-0" align="justify">
                                    - DUPA masurarea tensiunii arteriale
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">Asteptati un minut si repetati din nou masurarea tensiunii arteriale</li>
                                    <li className="f_400 mb-0" align="justify">Faceti media celor doua valori obtinute</li>
                                    <li className="f_400 mb-0" align="justify">Notati valoarea obtinuta intr-un tabel si aduceti-l la fiecare control medical</li>
                                    <li className="f_400 mb-0" align="justify">Aduceti tensiometrul utilizat pentru masurarea tensiunii arteriale atunci cand mergeti la controlul medical pentru a va asigura ca functioneaza corect.</li>
                                </ul>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Ce inseamna tensiune arteriala sistolica ("tensiunea mare") si tensiune arteriala diastolica ("tensiunea mica")?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Desi denumirile de "tensiune mare", respectiv "tensiune mica" nu sunt corecte din punct de vedere medical, acestea sunt utilizate in mod frecvent in vorbirea curenta pentru a inlocui termenii de tensiune arteriala sistolica, respectiv tensiune arteriala diastolica.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Tensiunea arteriala sistolica reprezinta presiunea sistolica pe care sangele o exercita asupra peretilor arterelor in timpul sistolei. Sistola reprezinta contractia inimii, resimtita sub forma de bataie a inimii, pentru a pompa sange catre intregul organism.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Tensiunea arteriala diastolica reprezinta presiunea pe care sangele o exercita asupra peretilor arterelor in timpul cand inima este in repaus intre doua batai, aceasta perioada purtand denumirea de diastola.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    In timpul contractiei inimii, prin pomparea unei cantitati suplimentare de sange in circulatia arteriala, presiunea sangelui asupra peretilor elastici ai vaselor va creste, in timp ce in perioada in care inima se relaxeaza sangele continua sa fie preluat de catre circulatia sistemica, iar presiunea de la nivelul peretilor vasculari va scadea. Astfel, este usor de inteles de ce tensiunea arteriala sistolica este mai mare decat tensiunea arteriala diastolica.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Care este valoarea normala a tensiunii arteriale?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Pentru a fi considerata "normala", valoarea tensiunii arteriale trebuie interpretata in context clinic, tinand cont de particularitatile fiecarei persoane.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    In general, este considerata o tensiune arteriala optima atunci cand tensiunea arteriala sistolica are valori sub 120 mmHg, iar tensiunea arteriala diastolica este sub 80 mmHg. Se accepta ca normale valori ale tensiunii arteriale mai mici de 130/85 mmHg.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Daca tensiunea arteriala este crescuta peste valorile normale inseamna ca am hipertensiune arteriala?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Nu. Exista mai multi factori care pot provoca cresterea temporara a tensiunii arteriale, cum ar fi stresul, anxietatea sau activitatea fizica. In unele cazuri, o singura valoare ridicata poate sa nu indice neaparat hipertensiune arteriala, dar cu siguranta este recomandata o monitorizare suplimentara pentru a determina daca aveti hipertensiune arteriala sau daca exista alte cauze pentru cresterea tensiunii arteriale.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    In mod uzual, diagnosticul de hipertensiune arteriala este pus atunci cand valorile tensiunii arteriale se mentin peste 140/90 mmHg pentru 3 zile consecutive.
                                </p>



                                <h1 className="f_p f_size_20 f_500 mb-30 text-center" style={{color: "#065ea5"}}>II GENERALITATI DESPRE HIPERTENSIUNEA ARTERIALA</h1>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Ce este hipertensiunea arteriala?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Hipertensiunea arteriala (HTA) este o afectiune medicala frecventa in care presiunea exercitata de coloana de sange asupra peretilor arterelor este constant ridicata peste valorile considerate normale. Hipertensiunea arteriala are o dubla calitate, putand fi considerata o boala si in acelasi timp un factor de risc, fiind cel mai comun factor de risc din punct de vedere cardio-vascular. Se estimeaza ca anual 10 milioane de oameni decedeaza din cauza hipertensiunii arteriale la nivel mondial.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Cat de frecventa este hipertensiunea arteriala in randul populatiei?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Conform estimarilor, prevalenta globala a hipertensiunii arteriale depaseste 1 miliard de persoane hipertensive. In randul adultilor, prevalenta generala a hipertensiunii arteriale este de aproximativ 30 - 45%. Putem astfel considera ca la nivel modial, 1 din 3 adulti are hipertensiune arteriala.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Hipertensiunea arteriala este mai frecventa odata cu inaintarea in varsta, cu o prevalenta de peste 60% in randul persoanelor cu varsta peste 60 de ani.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Pe masura ce populatiile imbatranesc si adopta un stil mai sedentar de viata, prevalenta hipertensiunii arteriale la nivel mondial va continua sa creasca. Se estimeaza ca numarul persoanelor cu hipertensiune arteriala va creste cu 15–20% pana in 2025, ajungand aproape de 1,5 miliarde.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Cum se pune diagnosticul de hipertensiune arteriala?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    In mod uzual, diagnosticul de hipertensiune arteriala este pus atunci cand valorile tensiunii arteriale se mentin peste 140/90 mmHg. Se considera ca pentru a diagnostica hipertensiunea arteriala la o persoana care nu era cunoscuta cu aceasta afectiune, este suficient ca la masurarea tensiunii arteriale dimineata si seara pentru cel putin 3 zile consecutive sa fie inregistrate valori crescute ale tensiunii arteriale.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Auto-monitorizarea tensiunii arteriale la domiciliu este o abordare comuna atunci cand o masuratoare de rutina a evidențiat o valoare crescuta a tensiunii arteriale. Acest lucru poate fi util pentru unele persoane decoare masuratorile luate intr-un cabinet medical pot fi uneori influentate de stresul emotional legat de a fi la medic. Dimpotriva, in cazul altor persoane, valorile tensiunii arteriale vor arata normal la consultul medical, dar vor fi ridicate la domiciliu din cauza factorilor de mediu.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Exista simptome care indica prezenta hipertensiunii arteriale?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    In cele mai multe cazuri, hipertensiunea arteriala nu provoaca niciun simptom, motiv pentru care este adesea numita "ucigasul tacut". Aceasta poate fi prezenta pentru perioade lungi de timp si poate evolua catre complicatii fara ca persoana sa isi dea seama de acest lucru sau sa se simta diferit. Unii oameni pot prezenta simptome daca tensiunea arteriala creste rapid catre valori foarte mari (asa cum se intampla in criza hipertensiva). Printre simptomele care insotesc complicatiile hipertensiunii arteriale si afectarea altor organe se numara: dureri de cap, stare de ametela, tulburari de vedere, palpitatii, durere in piept, dificultate in respiratie dupa efort, cresterea frecventei mictiunilor sau mictiuni nocturne, extremitati reci.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Cu toate acestea, merita mentionat din nou ca multe persoane cu hipertensiune arteriala pot sa nu prezinte niciun simptom vizibil, motiv pentru care monitorizarea regulata a tensiunii arteriale este cruciala.
                                </p>





                                <h1 className="f_p f_size_20 f_500 mb-30 text-center" style={{color: "#065ea5"}}>III FACTORI DE RISC</h1>
                                <p className="f_400 mb-30" align="justify">
                                    Intrucat hipertensiunea arteriala are o dubla calitate, aceea de a fi pe de o parte o boala de sine statatoare, iar pe de alta parte de a duce la aparitia altor boli odata cu evolutia sa, am considerat utila impartirea sectiunii dedicata factorilor de risc in doua categorii: factori de risc ce contribuie la aparitia hipertensiunii arteriale, respectiv factori de risc aditionali ce pot complica evolutia hipertensiunii arteriale cu alte boli.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Care sunt factorii de risc pentru aparitia hipertensiunii arteriale?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    De-a lungul timpului au fost identificati o serie de factori care cresc riscul ca o persoana sa dezvolte hipertensiune arteriala in timp. Printre cei mai importanti factori de risc asociati cu aparitia hipertensiunii arteriale se numara:
                                </p>
                                <ol>
                                    <li className="f_400 mb-0" align="justify">Varsta. Riscul de hipertensiune arteriala creste odata cu varsta, la varsta de 70 de ani aproximativ 70% dintre persoane vor avea hipertensiune arteriala.</li>
                                    <li className="f_400 mb-0" align="justify">Sexul masculin. In general, barbatii sunt mai predispusi la aparitia hipertensiunii arteriale, pana la varsta de 64 de ani, hipertensiunea arteriala find mai frecventa la barbati, dupa menopauza femeile fiind la randul lor mai predispuse</li>
                                    <li className="f_400 mb-0" align="justify">Istoric familial de hipertensiune arteriala. Este mai probabil sa dezvoltati hipertensiune arteriala daca aveti un parinte sau un frate cu aceasta afectiune.</li>
                                    <li className="f_400 mb-0" align="justify">Obezitate sau supraponderabilitate. Excesul de greutate asociat cu cresterea valorilor colesterolului provoaca modificari ce cresc adesea tensiunea arteriala.</li>
                                    <li className="f_400 mb-0" align="justify">Sedentarismul. Lipsa de activitate fizica regulata poate duce la cresterea in greutate si creste riscul de hipertensiune arteriala.</li>
                                    <li className="f_400 mb-0" align="justify">Fumatul. Fumatul, mestecatul tutunului sau vapatul cresc tensiunea arteriala imediat dupa consum pentru o perioada scurta de timp. In timp, fumatul lezeaza peretii vaselor de sange si accelereaza procesul de intarire a arterelor si riscul aparitiei hipertensiunii arteriale.</li>
                                    <li className="f_400 mb-0" align="justify">Prea multa sare. Excesul de sare in organism poate determina organismul sa retina lichide. Aceasta creste tensiunea arteriala.</li>
                                    <li className="f_400 mb-0" align="justify">Alcoolul in exces. Consumul de alcool a fost asociat cu cresterea tensiunii arteriale, in special la barbati.</li>
                                    <li className="f_400 mb-0" align="justify">Stres. Nivelurile ridicate de stres pot duce la o crestere temporara a tensiunii arteriale. Obiceiurile legate de stres, cum ar fi mancatul mai mult, consumul de tutun sau consumul de alcool, pot duce la cresterea suplimentara a tensiunii arteriale.</li>
                                    <li className="f_400 mb-0" align="justify">Alte boli cronice. Anumite afectiuni cronice precum bolile de rinichi, diabetul si apneea in somn sunt cateva dintre afectiunile care pot duce la hipertensiune arteriala.</li>
                                    <li className="f_400 mb-0" align="justify">Sarcina. Pe durata sarcinii poate aparea hipertensiune arteriala, care uneori poate atinge valori foarte mari, valorile tensiunii arteriale putandu-se mentine crescute si dupa nastere.</li>
                                </ol>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Care sunt factorii de risc aditionali ce pot complica evolutia unui pacient hipertensiv?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Unii dintre factorii de risc care contribuie la aparitia hipertensiunii arteriale pot contribui si la dezvoltarea complicatiilor legate de hipertensiunea arteriala. Printre acestia se numara varsta inaintata (peste 55 ani la barbati, respectiv 65 ani la femei), obezitatea, niveluri crescute ale colesterolului in sange, inactivitatea fizica, fumatul sau consumul excesiv de alcool. Altii factori care pot creste riscul de complicatii la persoanele cu hipertensiune arteriala includ:
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">Severitatea si durata hipertensiunii arteriale: cu cat tensiunea arteriala ramane crescuta pentru mai mult timp si cu cat valoarea sa este mai mare, cu atat creste riscul de complicatii.</li>
                                    <li className="f_400 mb-0" align="justify">Prezenta altor afectiuni de sanatate: persoanele cu hipertensiune arteriala care au si alte afectiuni de sanatate, cum ar fi diabetul, bolile renale sau alte boli cardiovasculare, pot prezenta un risc mai mare de complicatii.</li>
                                </ul>


                                <h1 className="f_p f_size_20 f_500 mb-30 text-center" style={{color: "#065ea5"}}>IV COMPLICATIILE HIPERTENSIUNII ARTERIALE</h1>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Care sunt posibilele complicatii ale hipertensiunii arteriale?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Hipertensiunea arteriala poate incepe sa produca modificari in organism cu ani de zile inainte de aparitia oricarui simptom. In ceea ce priveste complicatiile care pot aparea ca urmare a efectelor hipertensiunii arteriale, putem aminti:
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">Boli cardiovasculare: hipertensiunea arteriala poate creste riscul de boala cardiaca ischemica, infarct miocardic, tulburari ale ritmului cardiac sau aparitia insuficientei cardiace</li>
                                    <li className="f_400 mb-0" align="justify">Boala cerebrovasculara: aproximativ 60% dintre persoanele care au suferit un accident vascular cerebral au prezentat anterior hipertensiune arteriala</li>
                                    <li className="f_400 mb-0" align="justify">Declin cognitiv: hipertensiunea arteriala poate creste riscul de declin cognitiv si dementa, in special la adulti in varsta</li>
                                    <li className="f_400 mb-0" align="justify">Boala cronica de rinichi: Hipertensiunea arteriala poate afecta rinichii si creste riscul de a dezvolta boli renale cronice</li>
                                    <li className="f_400 mb-0" align="justify">Leziuni oculare: hipertensiunea arteriala poate provoca leziuni ale vaselor de sange din ochi, ducand la tulburari de vedere</li>
                                    <li className="f_400 mb-0" align="justify">Boala vasculara periferica: hipertensiunea arteriala poate provoca modificari la nivelul arterelor periferice, cauzate de o ingustare a arterelor care furnizeaza sange la nivelul membrelor</li>
                                    <li className="f_400 mb-0" align="justify">Complicatii ale sarcinii: Hipertensiunea arteriala in timpul sarcinii poate creste riscul de complicatii atat pentru mama, cat si pentru copil.</li>
                                </ul>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Cum poti preveni complicatiile hipertensiunii arteriale?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Pentru prevenirea complicatiilor asociate cu prezenta hipertensiunii arteriale, scopul primordial este de a preveni dezvoltarea tensiunii arteriale aparute odata cu varsta. Acest lucru poate fi realizat prin adaptari ale stilului de viata inca de la o varsta tanara prin mentinerea unui stil de viata activ, a un indice de masa corporala (IMC) normal si al unei diete echilibrate.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Din nefericire, majoritatea oamenilor nu respecta aceste recomandari si dezvolta hipertensiune arteriala la varsta adulta sau mai tarziu in viata. Pentru a preveni dezvoltarea complicatiilor odata ce a aparut hipertensiunea arteriala, este important sa mentineti nivelul tensiunii arteriale la valorile recomandate.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Pentru a realiza acest lucru este indicat sa:
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">Luati medicamentele conform prescriptiei medicale.</li>
                                    <li className="f_400 mb-0" align="justify">Mentineti un stil de viata sanatos. Aceasta include o dieta sanatoasa, exercitii fizice regulate, mentinerea unei greutati normale, limitarea consumului de sare, alcool, renuntarea la fumat si gestionarea stresului.</li>
                                    <li className="f_400 mb-0" align="justify">Monitorizati regulat tensiunea arteriala.</li>
                                    <li className="f_400 mb-0" align="justify">Gestionati corect alte afectiuni de sanatate: daca aveti alte afectiuni de sanatate (de exemplu diabet zaharat sau colesterol ridicat) este important sa le gestionati eficient pentru a reduce riscul de complicatii.</li>
                                    <li className="f_400 mb-0" align="justify">Urmati recomandarile medicului dumneavoastra si mergeti periodic la control.</li>
                                </ul>


                                <h1 className="f_p f_size_20 f_500 mb-30 text-center" style={{color: "#065ea5"}}>V TRATAMENTUL HIPERTENSIUNII ARTERIALE</h1>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Care este tratamentul hipertensiunii arteriale?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Tratamentul hipertensiunii arteriale cuprinde in primul rand masuri ce tin de modificarea stilului de viata (numite masuri terapeutice non-farmacologice), la care se poate asocia in functie de particularitatile individuale si tratament medicamentos (masuri terapeutice farmacologice).
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Asa cum am mentionat deja, modificarea stilului de viata este indicata tuturor pacientilor hipertensivi, fiind prima etapa in tratamentul hipertensiunii arteriale. Principalele masuri non-farmacologice in tratamentul hipertensiunii arteriale se refera la reducerea greutatii, mentinerea unei diete bogate in fructe, legume, cereale integrale si alimente cu continut scazut de grasimi, scaderea aportului de sare si sodiu, activitate fizica regulata, renuntarea la fumat, consum moderat de alcool.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Obiectivul tratamentului medicamentos antihipertensiv este scaderea valorilor tensionale la valori optime si reducerea riscului de aparitie al complicatiilor. Acesta se initiaza tinand cont de valorile tensiunii arteriale si de riscul cardiovascular global al pacientului, pentru initierea tratamentului hipertensiv si alegerea clasei de medicamente recomandate medicul va avea in vedere particularitatile individuale ale fiecarui pacient. Din aceasta cauza, este posibil ca tratamentul dumneavoastra sa difere fata de medicamentele pe care le administreaza cunoscutii dumneavoastra diagnosticati cu hipertensiune arteriala.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    De ce trebuie sa mananc nesarat daca am hipertensiune arteriala?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Daca aveti hipertensiune arteriala, reducerea consumului de sare este un pas important in gestionarea valorilor crescute ale tensiunii arteriale. Atunci cand consumam sare, aducem un aport suplimentar de sodiu in organism care contribuie la retinerea de lichid in exces. Acest lucru duce la cresterea tensiunii arteriale si poate scadea efectul medicamentelor antihipertensive.
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Pentru a reduce consumul de sare, puteti incerca sa:
                                </p>
                                <ul>
                                    <li className="f_400 mb-0" align="justify">Alegeti alimente si condimente cu continut scazut de sodiu sau fara sare adaugata</li>
                                    <li className="f_400 mb-0" align="justify">Gatiti acasa: pregatirea mancarurilor acasa va permite sa controlati cantitatea de sare adaugata. Folositi ierburi si condimente pentru a adauga aroma meselor in loc de sare</li>
                                    <li className="f_400 mb-0" align="justify">Alegeti alimente proaspete: fructele proaspete si legumele sunt in mod natural sarace in sodiu</li>
                                    <li className="f_400 mb-0" align="justify">Evitati alimentele procesate si ambalate, care contin adesea un nivel ridicat de sare</li>
                                    <li className="f_400 mb-0" align="justify">Cititi etichetele produselor pentru a verifica continutul de sodiu al alimentelor</li>
                                    <li className="f_400 mb-0" align="justify">Utilizati inlocuitori de sare: inlocuitorii de sare, cum ar fi clorura de potasiu, pot fi folositi ca inlocuitor pentru sarea de masa. Cu toate acestea, daca aveti afectiuni renale sau luati anumite medicamente, ar trebui sa va consultati medicul inainte de a utiliza un inlocuitor de sare.</li>
                                </ul>
                                <p className="f_400 mb-30" align="justify">
                                    Prin reducerea consumului de sare, puteti scadea tensiunea arteriala si puteti reduce riscul de a dezvolta complicatii asociate cu hipertensiunea arteriala, cum ar fi boli de inima, accident vascular cerebral si boli de rinichi.
                                </p>
                                <p className="f_400 mb-0 font-italic" align="justify" style={{color: "#065ea5"}}>
                                    Ce valori sunt recomandate pentru tensiunea arteriala sub tratament?
                                </p>
                                <p className="f_400 mb-30" align="justify">
                                    Valorile tensiunii arteriale care se doresc a fi atinse la persoanele cu varsta pana la 70 ani prin intermediul tratamentului antihipertensiv sunt in general de 120-130 mmHg pentru tensiunea arteriala sistolica, respectiv 70-79 mmHg pentru tensiunea arteriala diastolica. La persoanele cu varsta peste 70 de ani se accepta valori ale tensiunii arteriale sistolice pana la 140 mmHg.
                                </p>



                                <div className="post_share"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <Blogrightsidebar ServiceData={ServiceData}/>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default BlogSingle2;
