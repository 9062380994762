import React, {useEffect, useState} from 'react';
import CustomNavbar from "../../components/CustomNavbar";
import Breadcrumb from "../../components/Breadcrumb";
import FooterTwo from "../../components/Footer/FooterTwo";
import FooterData from "../../components/Footer/FooterData";
import BlogGrid from "../../components/Blog/BlogGrid";
import Blogsingle from "../../components/Blog/Blogsingle";
import Navbar from "../Navbar";
import ListaBloguri from "../Blog/ListaBloguri";
import axiosInstance from "../../components/Helper/AxiosHelper";
import AgencyBanner from "../../components/Banner/AgencyBanner";
import BannerData from "../../components/Banner/BannerData";
import AgencyAction from "../../components/AgencyAction";
import AddNoutate from "./AddNoutate";
import {getJwtRole} from "../../components/Helper/jwt_helper";

const Noutati = () => {

    return(
        <div className="body_wrapper">
            <Navbar mClass="menu_four" cClass="custom_container p0" nClass="pl_120 mr-auto ml-auto" hbtnClass="menu_cus"/>
            <AgencyBanner BannerData={BannerData}  image="" title="" title1="" title2="" description="" classBG="pagina_blog"/>
            {/*{getJwtRole() === "ADMIN" && (<AddNoutate/>)}*/}
            <BlogGrid />
            <AgencyAction FooterData={FooterData}/>
            <FooterTwo FooterData={FooterData}/>        </div>
    )
};

export default Noutati;
